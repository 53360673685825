import { ResultSet } from "@cubejs-client/core";

import { createAppAsyncThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class StoreGroupCorrelations {
    public readonly id: string;
    public readonly storeGroup: string;
    public readonly costTypeId: number;
    public readonly metricNameId: number;
    public readonly correlationValue: number;

    constructor(
        storeGroup: string,
        costTypeId: number,
        metricNameId: number,
        correlationValue: number
    ) {
        this.id = `${storeGroup}_${costTypeId}-${metricNameId}`;
        this.storeGroup = storeGroup;
        this.costTypeId = costTypeId;
        this.metricNameId = metricNameId;
        this.correlationValue = correlationValue;
    }
}

export const loadStoreGroupCorrelations = createAppAsyncThunk<
    StoreGroupCorrelations[]
>(
    "customer/insights/cost/loadStoreGroupCorrelations",
    async (arg, thunkAPI) => {
        try {
            const query = {
                dimensions: [
                    "StoreGroupCorrelation.StoreGroup",
                    "StoreGroupCorrelation.FK_AccountLine",
                    "StoreGroupCorrelation.FK_SimilarityMetricName",
                    "StoreGroupCorrelation.CorrelationValue"
                ]
            };
            const resultSet = await thunkAPI.dispatch(cubeLoad(query)) as unknown as ResultSet;

            return resultSet.rawData().map(row => {
                return new StoreGroupCorrelations(
                    row["StoreGroupCorrelation.StoreGroup"],
                    Number(row["StoreGroupCorrelation.FK_AccountLine"]),
                    Number(row["StoreGroupCorrelation.FK_SimilarityMetricName"]),
                    Number(row["StoreGroupCorrelation.CorrelationValue"])
                );
            });
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading store group correlations.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
