import React from "react";

import { Box, Typography } from "@material-ui/core";

import Spacer from "components/Spacer";

import ExportData from "./exportData/ExportData";
import Filters from "./filters/Filters";
import Information from "./information/Information";
import SavedLocations from "./savedLocations/SavedLocations";

const Header: React.FC = () => {
    return (
        <Box display="flex" alignItems="center" data-cy="header">
            <Typography variant="h3" component="div">
                Location
            </Typography>
            <Information />
            <Spacer />
            <ExportData />
            <SavedLocations />
            <Filters />
        </Box>
    );
};

export default Header;
