import { PivotConfig, Query, ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { cubeLoadParallel } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export interface CatchmentCustomerProfiles {
    storeId: string;
    [key: string]: number | string | boolean;
}

const NUMBER_OF_SUPERGROUPS = 8;

export const loadCatchmentCustomerProfiles = (numberOfStores: number): AppThunk<Promise<CatchmentCustomerProfiles[]>> => async (dispatch) => {
    try {
        const query: Query = {
            measures: [
                "ClientStoresCatchmentPopulation.SumWeightedPopulation"
            ],
            dimensions: [
                "D_Store.StoreNaturalID",
                "ClientStoresCatchmentPopulation.SupergroupName",
            ],
            segments: [
                "D_Store.OpenPhysicalStores"
            ]
        };
        const estimatedRowsCount = NUMBER_OF_SUPERGROUPS * numberOfStores;
        const resultSets = await dispatch(cubeLoadParallel(query, estimatedRowsCount, true)) as unknown as ResultSet[];
        const pivotedRows: CatchmentCustomerProfiles[] = [];

        const pivotConfig: PivotConfig = {
            x: ["D_Store.StoreNaturalID", 'measures'],
            y: ["ClientStoresCatchmentPopulation.SupergroupName"]
        };

        resultSets.forEach(resultSet => {
            pivotedRows.push(...resultSet.tablePivot(pivotConfig).map(row => {
                const storeId = String(row["D_Store.StoreNaturalID"]);
                delete row["D_Store.StoreNaturalID"];
                delete row["measures"];
                return {
                    storeId,
                    ...row
                };
            }));
        });
        return pivotedRows;
    } catch (error) {
        dispatch(logError("Error loading CatchmentCustomerProfiles.", error));
        throw error;
    }
};
