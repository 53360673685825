import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

import { clearCostOverview, loadCostOverview } from "./costOverview/costOverviewSlice";
import { DateTime } from "luxon";
import { loadReferenceDate } from "./referenceDate";
import { loadStores, Store } from "./store";
import { CostsByStore, loadCostsByStores } from "./costsByStore";
import { median } from "mathjs";
import mathUtils from "utils/mathUtils";
import { numberSortExpression, SortDirection } from "utils/sortUtils";
import { DataWrapper } from "domain/dataWrapper";
import { CostReductionOpportunityByStore } from "./costReductionOpportunityByStore";
import { CostType, loadCostTypes } from "./costType";
import { StoreGroup, loadStoreGroups } from "./storeGroups";
import { Region, loadRegions } from "./regions";
import { loadCostDrivers } from "./costDrivers/costDriversSlice";
import { clearFilters } from "./filters/filtersSlice";
import { SimilarityMetric, loadSimilarityMetrics } from "./similarityMetric";
import { SimilarityMetricValue, loadSimilarityMetricValues } from "./similarityMetricValues";
import { StoreGroupCorrelations, loadStoreGroupCorrelations } from "./storeGroupCorrelations";
import { GridColDef } from "@mui/x-data-grid-pro";
import numberFormatter from "utils/numberFormatter";
import { loadNumberOfStores } from "./numberOfStores";

export enum CostChapter {
    CostOverview = 1,
    CostDrivers,
    StoreCosts
}

interface LoadCostSetupResponse {
    referenceDate: DateTime,
    costTypes: CostType[],
    storeGroups: StoreGroup[],
    regions: Region[],
    similarityMetrics: SimilarityMetric[],
    numberOfStores: number
}

interface CostState {
    isLoading: boolean,
    hasErrors: boolean,
    currentChapter: CostChapter,
    referenceDate: DateTime,
    costTypes: CostType[],
    storeGroups: StoreGroup[],
    regions: Region[],
    similarityMetrics: SimilarityMetric[],
    numberOfStores: number,
    similarityMetricValues: DataWrapper<SimilarityMetricValue[]>,
    storeGroupCorrelations: DataWrapper<StoreGroupCorrelations[]>,
    stores: DataWrapper<Store[]>,
    costsByStores: DataWrapper<CostsByStore[]>,
    selectedStoreByCostType?: CostReductionOpportunityByStore
}

const initialState: CostState = {
    isLoading: false,
    hasErrors: false,
    currentChapter: CostChapter.CostOverview,
    referenceDate: DateTime.fromMillis(0, { zone: "utc" }),
    costTypes: [],
    storeGroups: [],
    regions: [],
    similarityMetrics: [],
    numberOfStores: 0,
    similarityMetricValues: { isLoading: false, hasErrors: false, data: [] },
    storeGroupCorrelations: { isLoading: false, hasErrors: false, data: [] },
    stores: { isLoading: false, hasErrors: false, data: [] },
    costsByStores: { isLoading: false, hasErrors: false, data: [] },
    selectedStoreByCostType: undefined
};

const costSlice = createSlice({
    name: "customer/inisghts/cost",
    initialState,
    reducers: {
        setCurrentChapter: (state, action: PayloadAction<CostChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        },
        clearReferenceDate: (state) => {
            state.referenceDate = initialState.referenceDate;
        },
        clearCostTypes: (state) => {
            state.costTypes = initialState.costTypes;
        },
        clearStoreGroups: (state) => {
            state.storeGroups = initialState.storeGroups;
        },
        clearRegions: (state) => {
            state.regions = initialState.regions;
        },
        clearSimilarityMetrics: (state) => {
            state.similarityMetrics = initialState.similarityMetrics;
        },
        clearNumberOfStores: (state) => {
            state.numberOfStores = initialState.numberOfStores;
        },
        clearSimilarityMetricValues: (state) => {
            state.similarityMetricValues = initialState.similarityMetricValues;
        },
        clearStoreGroupCorrelations: (state) => {
            state.storeGroupCorrelations = initialState.storeGroupCorrelations;
        },
        chooseCandidateStoreByCostType: (state, action: PayloadAction<CostReductionOpportunityByStore>) => {
            state.selectedStoreByCostType = action.payload;
        },
        clearSelectedStoreByCostType: (state) => {
            state.selectedStoreByCostType = initialState.selectedStoreByCostType;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCostSetup.pending, (state: CostState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.referenceDate = initialState.referenceDate;
            state.costTypes = initialState.costTypes;
            state.storeGroups = initialState.storeGroups;
            state.regions = initialState.regions;
            state.similarityMetrics = initialState.similarityMetrics;
        });
        builder.addCase(loadCostSetup.rejected, (state: CostState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.referenceDate = initialState.referenceDate;
            state.costTypes = initialState.costTypes;
            state.storeGroups = initialState.storeGroups;
            state.regions = initialState.regions;
            state.similarityMetrics = initialState.similarityMetrics;
        });
        builder.addCase(loadCostSetup.fulfilled, (state: CostState, action: PayloadAction<LoadCostSetupResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.referenceDate = action.payload.referenceDate;
            state.costTypes = action.payload.costTypes;
            state.storeGroups = action.payload.storeGroups;
            state.regions = action.payload.regions;
            state.similarityMetrics = action.payload.similarityMetrics;
        });
        builder.addCase(loadSimilarityMetricValues.pending, (state: CostState) => {
            state.similarityMetricValues.isLoading = true;
            state.similarityMetricValues.hasErrors = false;
            state.similarityMetricValues.data = initialState.similarityMetricValues.data;
        });
        builder.addCase(loadSimilarityMetricValues.rejected, (state: CostState) => {
            state.similarityMetricValues.isLoading = false;
            state.similarityMetricValues.hasErrors = true;
            state.similarityMetricValues.data = initialState.similarityMetricValues.data;
        });
        builder.addCase(loadSimilarityMetricValues.fulfilled, (state: CostState, action: PayloadAction<SimilarityMetricValue[]>) => {
            state.similarityMetricValues.isLoading = false;
            state.similarityMetricValues.hasErrors = false;
            state.similarityMetricValues.data = action.payload;
        });
        builder.addCase(loadStoreGroupCorrelations.pending, (state: CostState) => {
            state.storeGroupCorrelations.isLoading = true;
            state.storeGroupCorrelations.hasErrors = false;
            state.storeGroupCorrelations.data = initialState.storeGroupCorrelations.data;
        });
        builder.addCase(loadStoreGroupCorrelations.rejected, (state: CostState) => {
            state.storeGroupCorrelations.isLoading = false;
            state.storeGroupCorrelations.hasErrors = true;
            state.storeGroupCorrelations.data = initialState.storeGroupCorrelations.data;
        });
        builder.addCase(loadStoreGroupCorrelations.fulfilled, (state: CostState, action: PayloadAction<StoreGroupCorrelations[]>) => {
            state.storeGroupCorrelations.isLoading = false;
            state.storeGroupCorrelations.hasErrors = false;
            state.storeGroupCorrelations.data = action.payload;
        });
        builder.addCase(loadStores.pending, (state: CostState) => {
            state.stores.isLoading = true;
            state.stores.hasErrors = false;
            state.stores.data = initialState.stores.data;
        });
        builder.addCase(loadStores.rejected, (state: CostState) => {
            state.stores.isLoading = false;
            state.stores.hasErrors = true;
            state.stores.data = initialState.stores.data;
        });
        builder.addCase(loadStores.fulfilled, (state: CostState, action: PayloadAction<Store[]>) => {
            state.stores.isLoading = false;
            state.stores.hasErrors = false;
            state.stores.data = action.payload;
        });
        builder.addCase(loadCostsByStores.pending, (state: CostState) => {
            state.costsByStores.isLoading = true;
            state.costsByStores.hasErrors = false;
            state.costsByStores.data = initialState.costsByStores.data;
        });
        builder.addCase(loadCostsByStores.rejected, (state: CostState) => {
            state.costsByStores.isLoading = false;
            state.costsByStores.hasErrors = true;
            state.costsByStores.data = initialState.costsByStores.data;
        });
        builder.addCase(loadCostsByStores.fulfilled, (state: CostState, action: PayloadAction<CostsByStore[]>) => {
            state.costsByStores.isLoading = false;
            state.costsByStores.hasErrors = false;
            state.costsByStores.data = action.payload;
        });
    }
});

export const {
    setCurrentChapter,
    chooseCandidateStoreByCostType
} = costSlice.actions;

export const loadCostSetup = createAppAsyncThunk(
    "customer/insights/cost/loadCostSetup",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());

            const referenceDatePromise = thunkAPI.dispatch(loadReferenceDate());
            const costTypesPromise = thunkAPI.dispatch(loadCostTypes());
            const storeGroupsPromise = thunkAPI.dispatch(loadStoreGroups());
            const regionsPromise = thunkAPI.dispatch(loadRegions());
            const similarityMetricsPromise = thunkAPI.dispatch(loadSimilarityMetrics());
            const numberOfStoresPromise = thunkAPI.dispatch(loadNumberOfStores());
            const setupResults = await Promise.all([
                referenceDatePromise,
                costTypesPromise,
                storeGroupsPromise,
                regionsPromise,
                similarityMetricsPromise,
                numberOfStoresPromise
            ]);

            const referenceDate = setupResults[0];
            const costTypes = setupResults[1];
            const storeGroups = setupResults[2];
            const regions = setupResults[3];
            const similarityMetrics = setupResults[4];
            const numberOfStores = setupResults[5];

            const loadCostResponse: LoadCostSetupResponse = {
                referenceDate,
                costTypes,
                storeGroups,
                regions,
                similarityMetrics,
                numberOfStores
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Cost Setup."));
            return thunkAPI.rejectWithValue(null);
        }
        finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearCost = (): AppThunk => async (dispatch) => {
    dispatch(costSlice.actions.resetCurrentChapter());
    dispatch(costSlice.actions.clearReferenceDate());
    dispatch(costSlice.actions.clearCostTypes());
    dispatch(costSlice.actions.clearStoreGroups());
    dispatch(costSlice.actions.clearRegions());
    dispatch(costSlice.actions.clearSimilarityMetrics());
    dispatch(costSlice.actions.clearNumberOfStores());
    dispatch(costSlice.actions.clearSimilarityMetricValues());
    dispatch(costSlice.actions.clearStoreGroupCorrelations());
    dispatch(costSlice.actions.clearSelectedStoreByCostType());
    dispatch(clearInsights());
    dispatch(clearFilters());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        await dispatch(loadCostSetup());

        const state = getState();
        const referenceDate = selectReferenceDate(state);
        const similarityMetrics = selectSimilarityMetrics(state);
        const costTypes = selectCostTypes(state);
        const numberOfStores = selectNumberOfStores(state);

        dispatch(loadSimilarityMetricValues({ numberOfStores, numberOfSimilarityMetrics: similarityMetrics.length }));
        dispatch(loadStoreGroupCorrelations());
        dispatch(loadStores({ referenceDate }));
        dispatch(loadCostsByStores({ referenceDate, numberOfStores, numberOfCostTypes: costTypes.length }));
        dispatch(loadCostOverview());
        dispatch(loadCostDrivers());
    }
    catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(clearCostOverview());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.root.hasErrors;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.insights.cost.root.currentChapter;
};

export const selectReferenceDate = (state: RootState) => {
    return state.customer.insights.cost.root.referenceDate;
};

export const selectCostTypes = (state: RootState) => {
    return state.customer.insights.cost.root.costTypes;
};

export const selectStoreGroups = (state: RootState) => {
    return state.customer.insights.cost.root.storeGroups;
};

export const selectRegions = (state: RootState) => {
    return state.customer.insights.cost.root.regions;
};

export const selectSimilarityMetrics = (state: RootState) => {
    return state.customer.insights.cost.root.similarityMetrics;
};

export const selectNumberOfStores = (state: RootState) => {
    return state.customer.insights.cost.root.numberOfStores;
};

export const selectSimilarityMetricValues = (state: RootState) => {
    return state.customer.insights.cost.root.similarityMetricValues;
};

export const selectStoreGroupCorrelations = (state: RootState) => {
    return state.customer.insights.cost.root.storeGroupCorrelations;
};

export const selectStores = (state: RootState) => {
    return state.customer.insights.cost.root.stores;
};

export const selectCostsByStores = (state: RootState) => {
    return state.customer.insights.cost.root.costsByStores;
};

export const selectSelectedStoreByCostType = (state: RootState) => {
    return state.customer.insights.cost.root.selectedStoreByCostType;
};

export const selectStoreSimilarityMetricValues = createSelector(
    selectSimilarityMetrics,
    selectSimilarityMetricValues,
    (metrics, metricValues) => {
        const data = metricValues.data.map(metricValue => {
            const metricName = metrics.find(metric => metric.id === metricValue.metricNameId)?.name;
            return {
                ...metricValue,
                metricName: metricName
            };
        });

        return data;
    }
);

export const selectCostReductionOpportunityByStore = createSelector(
    selectStores,
    selectStoreSimilarityMetricValues,
    selectCostsByStores,
    (stores, storeSimilarityMetricValues, costsByStore) => {
        const costReductionOpportunityByStore: DataWrapper<CostReductionOpportunityByStore[]> = {
            isLoading: stores.isLoading || costsByStore.isLoading,
            hasErrors: stores.hasErrors || costsByStore.hasErrors,
            data: []
        };

        if (costReductionOpportunityByStore.isLoading || costReductionOpportunityByStore.hasErrors) {
            return costReductionOpportunityByStore;
        }

        const costsWithStores = costsByStore.data.map(costByStore => {
            const store = stores.data.find(store => store.id === costByStore.storeId);
            const similarityMetricValues = storeSimilarityMetricValues.filter(metricValue => metricValue.storeId === store?.id);

            const similarityMetricValuesProperties: { [key: string]: number } = {};

            similarityMetricValues.forEach(metric => {
                const dynamicKey = metric.metricName ?? "Unknown";
                similarityMetricValuesProperties[dynamicKey] = metric.metricValue;
            });

            return {
                ...costByStore,
                ...store,
                ...similarityMetricValuesProperties,
                id: costByStore.id,
                similarityMetricValues: similarityMetricValues
            };
        });

        const costsWithStoresAndOpportunity: CostReductionOpportunityByStore[] = costsWithStores.map(currentStore => {
            const currentCostType = currentStore.costName;
            const similarStoreIds = currentStore.similarStores?.map(similarStore => similarStore.id) ?? [];
            const similarStores = costsWithStores.filter(store => similarStoreIds.includes(store.storeId) && currentCostType === store.costName);
            const similarStoresAverageStoreCostValue = similarStores.length === 0 ? 0 :
                median(similarStores.map(similarStore => similarStore.costValue));
            const similarStoresAverageCostPercentageOfRevenue = similarStores.length === 0 ? 0 :
                median(similarStores.map(similarStore => mathUtils.safePercentage(similarStore.costValue, (similarStore?.revenue ?? 0)) / 100));

            const averageSimilarityScore = similarStores.length === 0 ? 0 :
                median(currentStore.similarStores?.map(similarStore => similarStore.similarityScore) ?? []);

            const currentStoreCostAsPercentage = mathUtils.safePercentage(currentStore.costValue, (currentStore?.revenue ?? 0)) / 100;
            const opportunityValueAsPercentageOfRevenue = currentStoreCostAsPercentage - similarStoresAverageCostPercentageOfRevenue;
            const opportunityValue = opportunityValueAsPercentageOfRevenue * (currentStore?.revenue ?? 0);

            return {
                ...currentStore,
                storeId: currentStore.storeId,
                storeName: currentStore.name,
                openingDate: currentStore.openingDate,
                retailCentreId: currentStore.retailCentreId,
                averageSimilarityScore: averageSimilarityScore,
                opportunityValue: opportunityValue,
                opportunityValueAsPercentageOfRevenue: opportunityValueAsPercentageOfRevenue,
                costAsPercentageOfRevenue: currentStoreCostAsPercentage,
                similarStoresAverageCostValue: similarStoresAverageStoreCostValue,
                similarStoresAverageCostPercentageOfRevenue: similarStoresAverageCostPercentageOfRevenue
            };
        }
        );

        costReductionOpportunityByStore.data = costsWithStoresAndOpportunity.sort((a, b) => numberSortExpression(a.opportunityValue, b.opportunityValue, SortDirection.DESC));
        return costReductionOpportunityByStore;
    }
);

export const selectSimilarityMetricColumns = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCostReductionOpportunityByStore,
    (isLoading, hasErrors, costReductionOpportunityByStore) => {
        if (isLoading || hasErrors || costReductionOpportunityByStore.data.length === 0) {
            return [];
        }

        const metricNames = new Set<string>();

        costReductionOpportunityByStore.data.forEach(record => {
            // @ts-ignore
            record.similarityMetricValues.forEach(metric => {
                metricNames.add(metric.metricName);
            });
        });

        const keys = Array.from(metricNames).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        const columns: GridColDef[] = keys.map(key => ({
            field: key,
            type: "number",
            flex: 1,
            headerName: key,
            sortable: true,
            hide: true,
            valueGetter: (params) => params.value ?? 0,
            renderCell: (params) => numberFormatter.toDecimalPlaces(params.value ?? 0, 1),
        }));
        return columns;
    }
);

export default costSlice;
