import React from "react";

import { Card, CardActionArea, CardActions, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import RadarChart from "pages/customer/insights/portfolioNew/filters/RadarChart";

import { Comparator, ComparatorType } from "modules/customer/insights/portfolioNew/comparator";
import numberFormatter from "utils/numberFormatter";
import { median } from "mathjs";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        height: "100%"
    }
}))(Card);

const StyledCardActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light,
        height: theme.spacing(15)
    }
}))(CardActions);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        height: "100%",
        minWidth: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(CardContent);

interface ComparatorCardProps {
    comparator: Comparator,
    selected: boolean,
    onSelect: (comparator: Comparator) => void,
    onDeselect: (comparator: Comparator) => void
}

const ComparatorCard: React.FC<ComparatorCardProps> = (props) => {
    const theme = useTheme();
    const { comparator, selected, onSelect, onDeselect } = props;

    const comparatorStores = comparator.getStores();
    const medianRevenue = comparatorStores.length > 0 ? median(comparatorStores.map(x => x.revenue)) : 0;
    const medianProfit = comparatorStores.length > 0 ? median(comparatorStores.map(x => x.grossProfitMargin)) : 0;
    const medianChangeInNumberOfStores = comparatorStores.length > 0 ? median(comparatorStores.map(x => x.changeInNumberOfStores)) : 0;
    const medianCatchmentSize = comparatorStores.length > 0 ? median(comparatorStores.map(x => x.catchmentSize)) : 0;
    const medianNumberOfCompetitors = comparatorStores.length > 0 ? median(comparatorStores.map(x => x.numberOfCompetitors)) : 0;
    const medianFootfallLevel = comparatorStores.length > 0 ? median(comparatorStores.map(x => x.footfallLevel)) : 0;

    const handleClick = () => {
        if (selected) {
            onDeselect(comparator);
        } else {
            onSelect(comparator);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardActions>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {comparator?.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                {comparator.type !== ComparatorType.Custom ? "Revenue" : <p></p>}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {comparator.type !== ComparatorType.Custom ? numberFormatter.toGBP(medianRevenue, 1) : <p></p>}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                {comparator.type !== ComparatorType.Custom ? "Gross Profit" : <p></p>}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {comparator.type !== ComparatorType.Custom ? numberFormatter.toPercentage(medianProfit, false, 0) : <p></p>}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} />
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                {comparator.type !== ComparatorType.Custom ? "Revenue/ft²" : <p></p>}
                            </Typography>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {comparator.displayRevenuePerSquareFoot()}
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledCardActions>
                <StyledCardContent>
                    <RadarChart
                        revenue={medianRevenue}
                        revenueScore={comparator?.getRevenueScore()}
                        profit={medianProfit}
                        profitScore={comparator?.getProfitScore()}
                        changeInNumberOfStores={medianChangeInNumberOfStores}
                        areaHealthScore={comparator?.getAreaHealthScore()}
                        catchmentSize={medianCatchmentSize}
                        catchmentScore={comparator?.getCatchmentScore()}
                        numberOfCompetitors={medianNumberOfCompetitors}
                        competitionScore={comparator?.getCompetitionScore()}
                        footfallLevel={medianFootfallLevel}
                        footfallScore={comparator?.getFootfallScore()}
                        totalScore={comparator?.getTotalScore()}
                    />
                </StyledCardContent>
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default ComparatorCard;
