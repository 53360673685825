import React from "react";

import { Box } from "@material-ui/core";
import useColourPalette from "components/visuals/useColourPalette";

import OutputAreasMap from "components/visuals/maps/OutputAreasMap";
import Explore from "pages/customer/tools/location/map/Explore";
import PinnedLocation from "pages/customer/tools/location/map/PinnedLocation";

import Legend from "./Legend";
import StreetView from "./StreetView";

import { selectCatchmentCustomerProfiles, selectPinnedLocation } from "modules/customer/tools/location/locationSlice";
import { selectHasPrediction, selectPredictedCatchments } from "modules/customer/tools/location/overview/overviewSlice";
import { selectMapboxConfiguration } from "modules/siteConfiguration/siteConfigurationSlice";
import { useAppSelector } from "store";

const Map: React.FC = () => {
    const colourPalette = useColourPalette();
    const mapboxConfiguration = useAppSelector(selectMapboxConfiguration);
    const retailCentre = useAppSelector(selectPinnedLocation)?.retailCentre;
    const catchmentCustomerProfiles = useAppSelector(selectCatchmentCustomerProfiles);
    const hasPrediction = useAppSelector(selectHasPrediction);
    const predictedCatchments = useAppSelector(selectPredictedCatchments);

    const [initialViewport, setInitialViewport] = React.useState({
        latitude: retailCentre?.latitude ?? 0,
        longitude: retailCentre?.longitude ?? 0,
        zoom: 13.5,
        minZoom: 4
    });

    const getCatchmentBand = (probability: number): string => {
        if (probability >= 0.8) {
            return "Top 20%";
        }
        else if (probability >= 0.6 && probability < 0.8) {
            return "60% to 80%";
        }
        else if (probability >= 0.4 && probability < 0.6) {
            return "40% to 60%";
        }
        else if (probability >= 0.2 && probability < 0.4) {
            return "20% to 40%";
        }
        else {
            return "Below 20%";
        }
    };

    const mapOutputAreas = React.useMemo(() => {
        if (hasPrediction && predictedCatchments && predictedCatchments?.length > 0) {
            const predictedCatchmentsOutputAreas = predictedCatchments.map(predictedCatchment => {
                let colourGroup = 0;

                if (predictedCatchment.probability >= 0.8) {
                    colourGroup = 0;
                }
                else if (predictedCatchment.probability >= 0.6 && predictedCatchment.probability < 0.8) {
                    colourGroup = 1;
                }
                else if (predictedCatchment.probability >= 0.4 && predictedCatchment.probability < 0.6) {
                    colourGroup = 2;
                }
                else if (predictedCatchment.probability >= 0.2 && predictedCatchment.probability < 0.4) {
                    colourGroup = 3;
                }
                else {
                    colourGroup = 4;
                }

                return {
                    code: predictedCatchment.outputAreaCode,
                    probability: predictedCatchment.probability,
                    colourGroup
                };
            });

            const distinctPredictedCatchments = predictedCatchmentsOutputAreas.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.code === value.code
                ))
            );

            const exportData = predictedCatchments.map(item => {
                const newItem = { ...item };
                
                for (let key in newItem) {
                    // @ts-ignore
                    if (newItem[key] === null) {
                        // @ts-ignore
                        delete newItem[key];
                    }
                }

                return newItem;
            });

            return {
                mapData: distinctPredictedCatchments,
                exportData: exportData
            };
        }

        const catchmentCustomerProfilesOutputAreas = catchmentCustomerProfiles.data.map(catchmentCustomerProfile => {
            let colourGroup = 0;

            if (catchmentCustomerProfile.probability >= 0.8) {
                colourGroup = 0;
            }
            else if (catchmentCustomerProfile.probability >= 0.6 && catchmentCustomerProfile.probability < 0.8) {
                colourGroup = 1;
            }
            else if (catchmentCustomerProfile.probability >= 0.4 && catchmentCustomerProfile.probability < 0.6) {
                colourGroup = 2;
            }
            else if (catchmentCustomerProfile.probability >= 0.2 && catchmentCustomerProfile.probability < 0.4) {
                colourGroup = 3;
            }
            else {
                colourGroup = 4;
            }

            return {
                code: catchmentCustomerProfile.outputAreaCode,
                colourGroup
            };
        });

        const exportData = catchmentCustomerProfiles.data.map(customerCatchmentProfile => {
            return {
                outputAreaCode: customerCatchmentProfile.outputAreaCode,
                probability: customerCatchmentProfile.probability,
                catchmentBand: getCatchmentBand(customerCatchmentProfile.probability)
            };
        });

        return {
            mapData: catchmentCustomerProfilesOutputAreas,
            exportData: exportData
        };
    }, [catchmentCustomerProfiles, hasPrediction, predictedCatchments]);

    React.useEffect(() => {
        setInitialViewport(current => {
            return {
                ...current,
                latitude: retailCentre?.latitude ?? 0,
                longitude: retailCentre?.longitude ?? 0
            };
        });
    }, [retailCentre]);

    return (
        <Box data-cy="overview-map">
            <OutputAreasMap
                loading={false}
                error={false}
                mapboxAccessToken={mapboxConfiguration.accessToken}
                mapboxBaseMapStyle={mapboxConfiguration.baseMapStyle}
                mapboxOutputAreasTilesetId={mapboxConfiguration.outputAreasTilesetId}
                mapboxOutputAreasTilesetUrl={mapboxConfiguration.outputAreasTilesetUrl}
                height="100vh"
                initialViewport={initialViewport}
                addGeocoder={false}
                outputAreas={mapOutputAreas.mapData}
                colours={colourPalette.locationOverview}
                enableHover={true}
                enableClick={false}
                displayPOIs={true}
                downloadData={mapOutputAreas.exportData}
            >
                <Explore />
                <PinnedLocation />
                <Legend />
                <StreetView />
            </OutputAreasMap>
        </Box>
    );
};

export default Map;
