import { RootState } from "store";
import { AppThunk, createAppAsyncThunk } from "appThunk";
import {
    PayloadAction,
    createSelector,
    createSlice
} from "@reduxjs/toolkit";

import { MonthlyCosts, loadMonthlyCosts } from "./monthlyCosts";
import { MonthlyRevenue, loadMonthlyRevenue } from "./monthlyRevenue";
import { DataWrapper } from "domain/dataWrapper";
import {
    selectCostReductionOpportunityByStore,
    selectCostsByStores,
    selectReferenceDate,
    selectSelectedStoreByCostType,
    selectStores,
    selectSimilarityMetricValues,
    selectSimilarityMetrics,
    selectStoreGroupCorrelations,
    selectCostTypes
} from "modules/customer/insights/cost/costSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { openStreetView as mapOpenStreetView } from "modules/helpers/maps/mapsSlice";
import { notifyError } from "modules/notifications/notificationsSlice";

import _ from "lodash";
import { DateTime } from "luxon";
import mathUtils from "utils/mathUtils";
import { SortDirection, numberSortExpression, stringSortExpression } from "utils/sortUtils";

export enum CostMeasure {
    CostValue,
    CostAsPercentageOfRevenue
}

export enum CostsOverTimeLineChartGranularity {
    Month,
    Quarter,
    Year
}

export enum ClusterStoresSortField {
    StoreName,
    CostAsPercentageOfRevenue,
    CostValue,
    Variance,
    SimilarityScore
}

interface ClusterStoresSort {
    field: ClusterStoresSortField,
    direction: SortDirection
}

interface StoreCostsState {
    isLoading: boolean,
    hasErrors: boolean,
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[],
    costMeasure: CostMeasure,
    costsOverTimeLineChartGranularity: CostsOverTimeLineChartGranularity,
    clusterStoresSort: ClusterStoresSort
}

const initialState: StoreCostsState = {
    isLoading: false,
    hasErrors: false,
    monthlyCosts: [],
    monthlyRevenue: [],
    costMeasure: CostMeasure.CostValue,
    costsOverTimeLineChartGranularity: CostsOverTimeLineChartGranularity.Month,
    clusterStoresSort: {
        field: ClusterStoresSortField.StoreName,
        direction: SortDirection.ASC
    }
};

interface LoadStoreCostsResponse {
    monthlyCosts: MonthlyCosts[],
    monthlyRevenue: MonthlyRevenue[]
}

const storeCostsSlice = createSlice({
    name: "customer/inisghts/cost/storeCosts",
    initialState,
    reducers: {
        clearMonthlyCosts: (state) => {
            state.monthlyCosts = initialState.monthlyCosts;
        },
        clearMonthlyRevenue: (state) => {
            state.monthlyRevenue = initialState.monthlyRevenue;
        },
        setCostMeasure: (state, action: PayloadAction<CostMeasure>) => {
            state.costMeasure = action.payload;
        },
        clearCostMeasure: (state) => {
            state.costMeasure = initialState.costMeasure;
        },
        setCostsOverTimeLineChartGranularity: (state, action: PayloadAction<CostsOverTimeLineChartGranularity>) => {
            state.costsOverTimeLineChartGranularity = action.payload;
        },
        clearCostsOverTimeLineChartGranularity: (state) => {
            state.costsOverTimeLineChartGranularity = initialState.costsOverTimeLineChartGranularity;
        },
        setClusterStoresSort: (state, action: PayloadAction<ClusterStoresSort>) => {
            state.clusterStoresSort = action.payload;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadStoreCosts.pending, (state: StoreCostsState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
        });
        builder.addCase(loadStoreCosts.rejected, (state: StoreCostsState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.monthlyCosts = initialState.monthlyCosts;
            state.monthlyRevenue = initialState.monthlyRevenue;
        });
        builder.addCase(loadStoreCosts.fulfilled, (state: StoreCostsState, action: PayloadAction<LoadStoreCostsResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.monthlyCosts = action.payload.monthlyCosts;
            state.monthlyRevenue = action.payload.monthlyRevenue;
        });
    }
});

export const {
    setCostMeasure,
    clearCostMeasure,
    setCostsOverTimeLineChartGranularity,
    clearCostsOverTimeLineChartGranularity,
    setClusterStoresSort
} = storeCostsSlice.actions;

export const loadStoreCosts = createAppAsyncThunk(
    "customer/insights/cost/storeCosts/loadStoreCosts",
    async (arg, thunkAPI) => {
        try {
            await thunkAPI.dispatch(setupCube());
            const state = thunkAPI.getState();

            const referenceDate = selectReferenceDate(state);
            const store = selectSelectedStoreByCostType(state);
            const costsByStore = selectCostsByStores(state);
            const similarStoresIds = costsByStore.data
                .find(item => item.storeId === store?.storeId
                    && item.costName === store.costName
                )?.similarStores
                .map(similarStore => similarStore.id);

            const monthlyCostsPromise = thunkAPI.dispatch(
                loadMonthlyCosts(
                    referenceDate,
                    store?.storeId,
                    similarStoresIds,
                    store?.costName
                )
            );

            const monthlyRevenuePromise = thunkAPI.dispatch(
                loadMonthlyRevenue(
                    referenceDate,
                    store?.storeId,
                    similarStoresIds,
                )
            );

            const results = await Promise.all([
                monthlyCostsPromise,
                monthlyRevenuePromise
            ]);
            const monthlyCosts = results[0];
            const monthlyRevenue = results[1];

            const loadCostResponse: LoadStoreCostsResponse = {
                monthlyCosts,
                monthlyRevenue
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading StoreCosts."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearStoreCosts = (): AppThunk => (dispatch) => {
    dispatch(storeCostsSlice.actions.clearMonthlyCosts());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.hasErrors;
};

export const selectMonthlyCosts = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.monthlyCosts;
};

export const selectMonthlyRevenue = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.monthlyRevenue;
};

export const selectCostMeasure = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.costMeasure;
};

export const selectCostsOverTimeLineChartGranularity = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.costsOverTimeLineChartGranularity;
};

export const selectClusterStoresSort = (state: RootState) => {
    return state.customer.insights.cost.storeCosts.clusterStoresSort;
};

export const openStreetView = (): AppThunk => (dispatch, getState) => {
    const state = getState();
    const selectedStore = selectSelectedStoreByCostType(state);
    const latitude = selectedStore?.latitude ?? 0;
    const longitude = selectedStore?.longitude ?? 0;
    dispatch(mapOpenStreetView(latitude, longitude));
};

export const selectClusterStoresCosts = createSelector(
    selectSelectedStoreByCostType,
    selectCostsByStores,
    selectCostReductionOpportunityByStore,
    (selectedStoreCostReductionOpportunity, costsByStores, costReductionOpportunityByStore) => {

        const selectedStoreCosts = costsByStores.data.find(item =>
            item.storeId === selectedStoreCostReductionOpportunity?.storeId
            && item.costName === selectedStoreCostReductionOpportunity.costName
        );

        if (!selectedStoreCostReductionOpportunity || !selectedStoreCosts) {
            return [];
        }

        const clusterStoreIDs = selectedStoreCosts.similarStores.map(item => item.id);

        return costReductionOpportunityByStore.data
            .filter(item => clusterStoreIDs.includes(item.storeId ?? "")
                && (item.costName === selectedStoreCostReductionOpportunity?.costName))
            .map(clusterStore => {
                const similarityScore =
                    selectedStoreCosts.similarStores.find(similarStore => similarStore.id === clusterStore.storeId)?.similarityScore ?? 0;
                return {
                    storeName: clusterStore.storeName ?? "",
                    costAsPercentageOfRevenue: clusterStore.costAsPercentageOfRevenue,
                    costValue: clusterStore.costValue,
                    variance: selectedStoreCostReductionOpportunity?.costValue - clusterStore.costValue,
                    similarityScore
                };
            })
            .sort((a, b) => numberSortExpression(a.similarityScore, b.similarityScore, SortDirection.DESC));
    }
);

export const selectStoreVsComparatorPerformance = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectSelectedStoreByCostType(state),
    (state: RootState) => selectStores(state),
    (state: RootState) => selectCostsByStores(state),
    (state: RootState) => selectSimilarityMetricValues(state),
    (state: RootState) => selectSimilarityMetrics(state),
    (state: RootState) => selectStoreGroupCorrelations(state),
    (state: RootState) => selectCostTypes(state),
    (storeCostsIsLoading, storeCostsHasErrors, store, stores, costsByStores, metricValues, metrics, correlations, costTypes) => {
        interface StoreVsComparatorPerformanceMetrics {
            metricNameId: number,
            metricName: string,
            metricValue: number
        }

        interface StoreVsComparatorPerformance {
            store: StoreVsComparatorPerformanceMetrics[],
            clusterAverage: StoreVsComparatorPerformanceMetrics[]
        }

        const isLoading = stores.isLoading || costsByStores.isLoading || metricValues.isLoading || correlations.isLoading || storeCostsIsLoading;
        const hasErrors = stores.hasErrors || costsByStores.hasErrors || metricValues.hasErrors || correlations.hasErrors || storeCostsHasErrors;

        const storeVsComparatorPerformance: DataWrapper<StoreVsComparatorPerformance> = {
            isLoading,
            hasErrors,
            data: {
                store: [],
                clusterAverage: []
            }
        };

        if (!store || storeVsComparatorPerformance.isLoading || storeVsComparatorPerformance.hasErrors) {
            return storeVsComparatorPerformance;
        }

        const selectedStoreCostType = costTypes.find(costType => costType.name === store.costName);

        // Store metric values
        const orderedCorrelations = correlations.data
            .filter(correlation => correlation.costTypeId === Number(selectedStoreCostType?.id)
                && correlation.storeGroup === store.group
            )
            .map(correlation => {
                return {
                    metricNameId: correlation.metricNameId,
                    correlationValue: Math.abs(correlation.correlationValue)
                };
            })
            .sort((a, b) => numberSortExpression(Math.abs(a.correlationValue), Math.abs(b.correlationValue), SortDirection.DESC))
            .slice(0, 6);

        const topSixMetricNameIds = orderedCorrelations.map(oc => oc.metricNameId);

        const storeMetricValues = metricValues.data
            .filter(mv => mv.storeId === store.storeId && topSixMetricNameIds.includes(mv.metricNameId))
            .map(mv => {
                const metric = metrics.find(metric => metric.id === mv.metricNameId);
                const correlationValue = orderedCorrelations.find(oc => oc.metricNameId === Number(metric?.id))?.correlationValue;

                return {
                    metricNameId: metric?.id ?? 0,
                    metricName: metric?.name ?? "",
                    metricValue: mv.metricValue,
                    correlationValue: correlationValue ?? 0
                };
            });

        storeVsComparatorPerformance.data.store = storeMetricValues
            .sort((a, b) => numberSortExpression(Math.abs(a.correlationValue), Math.abs(b.correlationValue), SortDirection.DESC));

        // Cluster average metric values
        const similarStoreIds = costsByStores.data
            .find(item =>
                item.storeId === store.storeId
                && item.costName === store.costName
            )?.similarStores
            .map(similarStore => similarStore.id);


        const clusterMetricValues = metricValues.data
            .filter(mv => similarStoreIds?.includes(mv.storeId) && topSixMetricNameIds.includes(mv.metricNameId))
            .map(mv => {
                const metric = metrics.find(metric => metric.id === mv.metricNameId);
                return {
                    metricNameId: metric?.id ?? 0,
                    metricName: metric?.name ?? "",
                    metricValue: mv.metricValue
                };
            });

        const averageClusterMetricValues = topSixMetricNameIds.map((metric) => {
            const similarityMetric = metrics.find(m => m.id === metric);
            const clusterMetric = clusterMetricValues.filter(clusterMetricValue => clusterMetricValue.metricNameId === metric);
            return {
                metricNameId: similarityMetric?.id ?? 0,
                metricName: similarityMetric?.name ?? "",
                metricValue: mathUtils.safeMedian(clusterMetric.map(x => x.metricValue)) ?? 0
            };
        });

        storeVsComparatorPerformance.data.clusterAverage = averageClusterMetricValues;

        return storeVsComparatorPerformance;
    }
);

export const selectCostsOverTime = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectSelectedStoreByCostType,
    selectMonthlyCosts,
    selectMonthlyRevenue,
    selectCostsOverTimeLineChartGranularity,
    (
        isLoading,
        hasErrors,
        store,
        monthlyCosts,
        monthlyRevenue,
        costsOverTimeLineChartGranularity
    ) => {
        interface LabourCost {
            date: DateTime,
            costValue: number,
            costAsPercentageOfRevenue: number
        }

        interface LabourCostOverTime {
            store: LabourCost[]
            clusterAverage: LabourCost[]
        }

        const labourCostsOverTime: DataWrapper<LabourCostOverTime> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: {
                store: [],
                clusterAverage: []
            }
        };

        if (!store || !monthlyCosts || !monthlyRevenue) {
            return labourCostsOverTime;
        }

        if (costsOverTimeLineChartGranularity === CostsOverTimeLineChartGranularity.Month) {
            // Selected store values
            const storeMonthlyCosts = monthlyCosts.filter(costs => costs.storeId === store.storeId);
            const storeMonthlyRevenue = monthlyRevenue.filter(revenue => revenue.storeId === store.storeId);
            storeMonthlyCosts.forEach(cost => {
                const revenue = storeMonthlyRevenue.find(revenue => revenue.date.equals(cost.date));
                labourCostsOverTime.data.store.push({
                    date: cost.date,
                    costValue: cost.costValue,
                    costAsPercentageOfRevenue: mathUtils.safePercentage(cost.costValue, (revenue ? revenue.revenue : 0))
                });
            });

            // Cluster average values
            const clusterMonthlyCosts = _(monthlyCosts)
                .filter(costs => costs.storeId !== store.storeId)
                .groupBy(monthlyCosts => monthlyCosts.date)
                .value();
            const clusterMonthlyRevenue = _(monthlyRevenue)
                .filter(revenue => revenue.storeId !== store.storeId)
                .groupBy(monthlyRevenue => monthlyRevenue.date)
                .value();

            const clusterData = _(clusterMonthlyCosts).map((monthlyCost, key) => {
                const monthlyRevenue = clusterMonthlyRevenue[key] ?? [];
                const costAsPercentageOfRevenue: number[] = [];
                monthlyCost.forEach(cost => {
                    const revenue = monthlyRevenue.find(r => r.storeId === cost.storeId);
                    costAsPercentageOfRevenue.push(mathUtils.safePercentage(cost.costValue, (revenue?.revenue ?? 0)));
                });

                return {
                    key: key,
                    cost: monthlyCost,
                    revenue: monthlyRevenue,
                    costAsPercentageRevenue: costAsPercentageOfRevenue
                };
            })
                .value();

            clusterData.forEach(cd => {
                labourCostsOverTime.data.clusterAverage.push({
                    date: DateTime.fromISO(cd.key, { zone: "utc" }),
                    costValue: mathUtils.safeMedian(cd.cost.map(cost => cost.costValue)) ?? 0,
                    costAsPercentageOfRevenue: mathUtils.safeMedian(cd.costAsPercentageRevenue) ?? 0
                });
            });
        }
        else if (costsOverTimeLineChartGranularity === CostsOverTimeLineChartGranularity.Year) {
            // Selected store values
            const storeYearlyCosts = _(monthlyCosts)
                .filter(costs => costs.storeId === store.storeId)
                .groupBy(costs => costs.date.year)
                .value();
            const storeYearlyRevenue = _(monthlyRevenue)
                .filter(revenue => revenue.storeId === store.storeId)
                .groupBy(revenue => revenue.date.year)
                .value();

            _(storeYearlyCosts).forEach(yearlyCost => {
                const yearlyRevenue: MonthlyRevenue[] = [];
                _(storeYearlyRevenue).forEach(revenue => {
                    revenue.forEach(r => {
                        if (r.date.year === yearlyCost[0].date.year) {
                            yearlyRevenue.push(r);
                        }
                    });
                });
                labourCostsOverTime.data.store.push({
                    date: yearlyCost[0].date,
                    costValue: yearlyCost.reduce((total, item) => item.costValue + total, 0),
                    costAsPercentageOfRevenue: mathUtils.safePercentage(
                        yearlyCost.reduce((total, item) => item.costValue + total, 0),
                        yearlyRevenue.reduce((total, item) => item.revenue + total, 0)
                    )
                });
            });

            // Cluster average values
            const clusterYearlyCosts = _(monthlyCosts)
                .filter(costs => costs.storeId !== store.storeId)
                .groupBy(costs => costs.date.year)
                .value();
            const clusterYearlyRevenue = _(monthlyRevenue)
                .filter(revenue => revenue.storeId !== store.storeId)
                .groupBy(revenue => revenue.date.year)
                .value();

            const clusterData = _(clusterYearlyCosts).map((yearlyCost, key) => {
                const yearlyRevenue = clusterYearlyRevenue[key] ?? [];

                const groupedCosts = _(yearlyCost).groupBy(cost => cost.storeId).value();
                const groupedRevenue = _(yearlyRevenue).groupBy(revenue => revenue.storeId).value();

                const storeYearData = _(groupedCosts).map((group, storeKey) => {
                    const revenue = groupedRevenue[storeKey];
                    const totalCosts = group.reduce((total, item) => item.costValue + total, 0);
                    const totalRevenue = (revenue !== undefined ? revenue.reduce((total, item) => item.revenue + total, 0) : 0);

                    return {
                        cost: totalCosts,
                        revenue: totalRevenue,
                        costPercentageOfRevenue: mathUtils.safePercentage(totalCosts, totalRevenue)
                    };
                }).value();

                const medianCostValue = mathUtils.safeMedian(storeYearData.map(data => data.cost)) ?? 0;
                const medianRevenue = mathUtils.safeMedian(storeYearData.map(data => data.revenue)) ?? 0;
                const medianCostPercentageOfRevenue = mathUtils.safeMedian(storeYearData.map(data => data.costPercentageOfRevenue)) ?? 0;

                return {
                    key: key,
                    cost: medianCostValue,
                    revenue: medianRevenue,
                    costAsPercentageRevenue: medianCostPercentageOfRevenue
                };
            })
                .value();

            clusterData.forEach(cd => {
                labourCostsOverTime.data.clusterAverage.push({
                    date: DateTime.fromISO(cd.key, { zone: "utc" }),
                    costValue: cd.cost,
                    costAsPercentageOfRevenue: cd.costAsPercentageRevenue ?? 0
                });
            });
        }
        else {
            // Selected store values
            const storeMonthlyCosts = monthlyCosts.filter(costs => costs.storeId === store.storeId);
            const storeMonthlyRevenue = monthlyRevenue.filter(costs => costs.storeId === store.storeId);
            const groupedStoreMonthlyCosts: Record<string, { costs: MonthlyCosts[], revenue: MonthlyRevenue[] }> = {};

            storeMonthlyCosts.forEach(cost => {
                const date = cost.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedStoreMonthlyCosts[key]) {
                    groupedStoreMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedStoreMonthlyCosts[key].costs.push(cost);
            });

            storeMonthlyRevenue.forEach(revenue => {
                const date = revenue.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedStoreMonthlyCosts[key]) {
                    groupedStoreMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedStoreMonthlyCosts[key].revenue.push(revenue);
            });

            const storeQuarterData = _(groupedStoreMonthlyCosts)
                .map((group, key) => {
                    return {
                        date: DateTime.fromISO(key),
                        costValue: group.costs.reduce((total, item) => item.costValue + total, 0),
                        costAsPercentageOfRevenue: mathUtils.safePercentage(
                            group.costs.reduce((total, item) => item.costValue + total, 0),
                            group.revenue.reduce((total, item) => item.revenue + total, 0)
                        )
                    };
                })
                .value();

            labourCostsOverTime.data.store = storeQuarterData;

            // Cluster average values
            const clusterMonthlyCosts = monthlyCosts.filter(costs => costs.storeId !== store.storeId);
            const clusterMonthlyRevenue = monthlyRevenue.filter(costs => costs.storeId !== store.storeId);
            const groupedClusterMonthlyCosts: Record<string, { costs: MonthlyCosts[], revenue: MonthlyRevenue[] }> = {};

            clusterMonthlyCosts.forEach(cost => {
                const date = cost.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedClusterMonthlyCosts[key]) {
                    groupedClusterMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedClusterMonthlyCosts[key].costs.push(cost);
            });

            clusterMonthlyRevenue.forEach(revenue => {
                const date = revenue.date;
                const year = date.year;
                const quarter = Math.ceil(date.month / 3);
                const firstMonthOfQuarter = (quarter - 1) * 3 + 1;

                const key = DateTime.fromObject({ year: year, month: firstMonthOfQuarter }).toISO();

                if (!groupedClusterMonthlyCosts[key]) {
                    groupedClusterMonthlyCosts[key] = { costs: [], revenue: [] };
                }
                groupedClusterMonthlyCosts[key].revenue.push(revenue);
            });

            _(groupedClusterMonthlyCosts).forEach((group, key) => {
                const groupedCosts = _(group.costs).groupBy(costs => costs.storeId).value();
                const groupedRevenue = _(group.revenue).groupBy(revenue => revenue.storeId).value();

                const storeQuarterData = _(groupedCosts).map((group, storeKey) => {
                    const revenue = groupedRevenue[storeKey];

                    const totalCosts = group.reduce((total, item) => item.costValue + total, 0);
                    const totalRevenue = (revenue !== undefined ? revenue.reduce((total, item) => item.revenue + total, 0) : 0);
                    const costAsPercentageOfRevenue = mathUtils.safePercentage(totalCosts, totalRevenue);

                    return {
                        cost: totalCosts,
                        revenue: totalRevenue,
                        costAsPercentageOfRevenue: costAsPercentageOfRevenue
                    };
                }).value();

                const medianCostValue = mathUtils.safeMedian(storeQuarterData.map(data => data.cost)) ?? 0;
                const medianCostAsPercentageOfRevenue = mathUtils.safeMedian(storeQuarterData.map(data => data.costAsPercentageOfRevenue)) ?? 0;

                labourCostsOverTime.data.clusterAverage.push({
                    date: DateTime.fromISO(key),
                    costValue: medianCostValue,
                    costAsPercentageOfRevenue: medianCostAsPercentageOfRevenue
                });
            });
        }

        return labourCostsOverTime;
    }
);

export const selectSortedClusterStores = createSelector(
    selectClusterStoresCosts,
    selectClusterStoresSort,
    (clusterStores, sort) => {
        if (!clusterStores) {
            return [];
        }

        return clusterStores
            .slice()
            .sort((a, b) => {
                switch (sort.field) {
                    case ClusterStoresSortField.StoreName:
                        return stringSortExpression(a.storeName, b.storeName, sort.direction);
                    case ClusterStoresSortField.CostAsPercentageOfRevenue:
                        return numberSortExpression(a.costAsPercentageOfRevenue, b.costAsPercentageOfRevenue, sort.direction);
                    case ClusterStoresSortField.CostValue:
                        return numberSortExpression(a.costValue, b.costValue, sort.direction);
                    case ClusterStoresSortField.Variance:
                        return numberSortExpression(a.variance, b.variance, sort.direction);
                    case ClusterStoresSortField.SimilarityScore:
                        return numberSortExpression(a.similarityScore ?? 0, b.similarityScore ?? 0, sort.direction);
                    default:
                        return stringSortExpression(a.storeName, b.storeName, SortDirection.ASC);
                }
            });
    }
);

export default storeCostsSlice;
