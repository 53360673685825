import React from "react";
import _ from "lodash";

import { Badge, Box, Button, Mark, Menu, MenuItem, Slider, TextField, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from "@material-ui/core/styles";

import Spacer from "components/Spacer";

import { PartnerStatus } from "modules/customer/tools/product/partner";
import {
    selectIsPartnersFilterModified,
    selectPartnerStatuses,
    selectHeadroom,
    selectOptimisedSales,
    selectTotalEstimatedSales,
    selectClientSourcedSales,
    selectPartnersFilter,
    setPartnersFilter,
    clearPartnersFilter,
    PartnersFilter
} from "modules/customer/tools/product/partnerFilters/partnerFiltersSlice";
import { useAppDispatch, useAppSelector } from "store";
import numberFormatter from "utils/numberFormatter";

const StyledMenu = withStyles(theme => ({
    paper: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    list: {
        width: theme.spacing(35)
    }
}))(Menu);

const StyledTextField = withStyles(theme => ({
    root: {
        "& .MuiOutlinedInput-root": {
            // @ts-ignore
            backgroundColor: theme.palette.quaternary.main
        }
    }
}))(TextField);

const Filter: React.FC = () => {
    const dispatch = useAppDispatch();
    const statuses = useAppSelector(selectPartnerStatuses);
    const headroom = useAppSelector(selectHeadroom);
    const optimisedSales = useAppSelector(selectOptimisedSales);
    const totalEstimatedSales = useAppSelector(selectTotalEstimatedSales);
    const clientSourcedSales = useAppSelector(selectClientSourcedSales);
    const partnersFilter = useAppSelector(selectPartnersFilter);
    const isPartnersFilterModified = useAppSelector(selectIsPartnersFilterModified);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const [/*currentPartnersFilter*/, setCurrentPartnersFilter] = React.useState<PartnersFilter>(partnersFilter);

    // Headroom slider values
    const headroomPercentileThresholds = headroom.percentileThresholds;
    const headroomSliderMinValue = headroomPercentileThresholds.indexOf(Math.max(headroom.minPercentileThreshold, partnersFilter.headroom[0]));
    const headroomSliderMaxValue = headroomPercentileThresholds.indexOf(Math.min(headroom.maxPercentileThreshold, partnersFilter.headroom[1]));
    const headroomSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < headroomPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(headroomPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [headroomPercentileThresholds]);

    // Optimised sales slider values
    const optimisedSalesPercentileThresholds = optimisedSales.percentileThresholds;
    const optimisedSalesSliderMinValue = optimisedSalesPercentileThresholds.indexOf(Math.max(optimisedSales.minPercentileThreshold, partnersFilter.optimisedSales[0]));
    const optimisedSalesSliderMaxValue = optimisedSalesPercentileThresholds.indexOf(Math.min(optimisedSales.maxPercentileThreshold, partnersFilter.optimisedSales[1]));
    const optimisedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < optimisedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(optimisedSalesPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [optimisedSalesPercentileThresholds]);

    // Total estimated sales slider values
    const totalEstimatedSalesPercentileThresholds = totalEstimatedSales.percentileThresholds;
    const totalEstimatedSalesSliderMinValue = totalEstimatedSalesPercentileThresholds.indexOf(Math.max(totalEstimatedSales.minPercentileThreshold, partnersFilter.totalEstimatedSales[0]));
    const totalEstimatedSalesSliderMaxValue = totalEstimatedSalesPercentileThresholds.indexOf(Math.min(totalEstimatedSales.maxPercentileThreshold, partnersFilter.totalEstimatedSales[1]));
    const totalEstimatedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < totalEstimatedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(totalEstimatedSalesPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [totalEstimatedSalesPercentileThresholds]);

    // Client sourced sales slider values
    const clientSourcedSalesPercentileThresholds = clientSourcedSales.percentileThresholds;
    const clientSourcedSalesSliderMinValue = clientSourcedSalesPercentileThresholds.indexOf(Math.max(clientSourcedSales.minPercentileThreshold, partnersFilter.clientSourcedSales[0]));
    const clientSourcedSalesSliderMaxValue = clientSourcedSalesPercentileThresholds.indexOf(Math.min(clientSourcedSales.maxPercentileThreshold, partnersFilter.clientSourcedSales[1]));
    const clientSourcedSalesSliderMarks = React.useMemo(() => {
        const marks: Mark[] = [];

        for (let i = 0; i < clientSourcedSalesPercentileThresholds.length; i++) {
            const value = i;
            const label = numberFormatter.toGBP(clientSourcedSalesPercentileThresholds[i], 0);
            marks.push({ value, label });
        }
        return marks;
    }, [clientSourcedSalesPercentileThresholds]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetAllClick = () => {
        dispatch(clearPartnersFilter());
    };

    const setPartnersFilterDelayed = React.useMemo(() => {
        return _.debounce((partnersFilter: PartnersFilter) => dispatch(setPartnersFilter(partnersFilter)), 400);
    }, [dispatch]);

    const handlePartnerStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const status = event.target.value as PartnerStatus;
        const newPartnersFilter = { ...partnersFilter, status };
        setCurrentPartnersFilter(newPartnersFilter);
        setPartnersFilterDelayed(newPartnersFilter);
    };

    const handleHeadroomChange = (event: React.ChangeEvent<{}>, value: any) => {
        const headroom = [headroomPercentileThresholds[value[0]], headroomPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...partnersFilter, headroom };
        setCurrentPartnersFilter(newPartnersFilter);
        setPartnersFilterDelayed(newPartnersFilter);
    };

    const handleOptimisedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const optimisedSales = [optimisedSalesPercentileThresholds[value[0]], optimisedSalesPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...partnersFilter, optimisedSales };
        setCurrentPartnersFilter(newPartnersFilter);
        setPartnersFilterDelayed(newPartnersFilter);
    };

    const handleTotalEstimatedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const totalEstimatedSales = [totalEstimatedSalesPercentileThresholds[value[0]], totalEstimatedSalesPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...partnersFilter, totalEstimatedSales };
        setCurrentPartnersFilter(newPartnersFilter);
        setPartnersFilterDelayed(newPartnersFilter);
    };

    const handleClientSourcedSalesChange = (event: React.ChangeEvent<{}>, value: any) => {
        const clientSourcedSales = [clientSourcedSalesPercentileThresholds[value[0]], clientSourcedSalesPercentileThresholds[value[1]]];
        const newPartnersFilter = { ...partnersFilter, clientSourcedSales };
        setCurrentPartnersFilter(newPartnersFilter);
        setPartnersFilterDelayed(newPartnersFilter);
    };

    React.useEffect(() => {
        setCurrentPartnersFilter(partnersFilter);
    }, [partnersFilter]);

    return (
        <>
            <Badge color="primary" variant="dot" overlap="rectangular" invisible={!isPartnersFilterModified}>
                <Button
                    variant="text"
                    size="small"
                    color="default"
                    disableElevation
                    startIcon={<FilterListIcon />}
                    onClick={handleClick}
                    data-cy="btn-filter"
                >
                    Filter
                </Button>
            </Badge>
            <StyledMenu
                open={open}
                onClose={handleClose}
                keepMounted
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Box paddingBottom={2} paddingRight={2} paddingLeft={2}>
                    <Box display="flex">
                        <Spacer />
                        <Button
                            variant="text"
                            size="small"
                            color="default"
                            disableElevation
                            onClick={handleResetAllClick}
                        >
                            Reset all
                        </Button>
                    </Box>
                    <Box padding={1}>
                        <Typography variant="body1" component="div" gutterBottom>
                            Partner status
                        </Typography>
                        <StyledTextField
                            variant="outlined"
                            size="small"
                            select
                            value={partnersFilter.status}
                            onChange={handlePartnerStatusChange}
                            SelectProps={{
                                displayEmpty: true,
                                MenuProps: {
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    }
                                }
                            }}
                            fullWidth
                        >
                            <MenuItem value="">
                                Select partner status
                            </MenuItem>
                            {statuses.map((status, index) =>
                                <MenuItem key={index} value={status}>
                                    {status}
                                </MenuItem>
                            )}
                        </StyledTextField>
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Headroom
                        </Typography>
                        <Slider
                            value={[headroomSliderMinValue, headroomSliderMaxValue]}
                            onChange={handleHeadroomChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={headroomSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Optimised sales
                        </Typography>
                        <Slider
                            value={[optimisedSalesSliderMinValue, optimisedSalesSliderMaxValue]}
                            onChange={handleOptimisedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={optimisedSalesSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Total estimated sales
                        </Typography>
                        <Slider
                            value={[totalEstimatedSalesSliderMinValue, totalEstimatedSalesSliderMaxValue]}
                            onChange={handleTotalEstimatedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={totalEstimatedSalesSliderMarks}
                        />
                    </Box>
                    <Box paddingTop={2}>
                        <Typography variant="body1" component="div">
                            Client sourced sales
                        </Typography>
                        <Slider
                            value={[clientSourcedSalesSliderMinValue, clientSourcedSalesSliderMaxValue]}
                            onChange={handleClientSourcedSalesChange}
                            step={null}
                            min={0}
                            max={5}
                            marks={clientSourcedSalesSliderMarks}
                        />
                    </Box>
                </Box>
            </StyledMenu>
        </>
    );
};

export default Filter;
