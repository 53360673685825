import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class SimilarityMetric {
    public readonly id: number;
    public readonly name: string;

    constructor(
        id: number,
        name: string
    ) {
        this.id = id;
        this.name = name;
    }
}

function returnMetricFriendlyName(name: string): string {
    switch (name) {
        case "Sqft":
            return "Store size";
        case "storeAgeDays":
            return "Store age";
        case "competitorSqft":
            return "Competitor store size";
        case "footfallDensityLTM":
            return "Footfall";
        case "onlineSalesProportion":
            return "Online sales proportion";
        default:
            return name;
    }
}

export const loadSimilarityMetrics = (): AppThunk<Promise<SimilarityMetric[]>> => async (dispatch) => {
    try {
        const query = {
            dimensions: [
                "SimilarityMetricName.PK_SimilarityMetricName",
                "SimilarityMetricName.SimilarityMetricName",
                "ClientFeatureName.ClientFeatureName",
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;

        return resultSet.rawData().map(row => {
            return new SimilarityMetric(
                Number(row["SimilarityMetricName.PK_SimilarityMetricName"]),
                returnMetricFriendlyName(row["ClientFeatureName.ClientFeatureName"] ?? row["SimilarityMetricName.SimilarityMetricName"])
            );
        });
    } catch (error) {
        dispatch(logError("Error loading SimilarityMetrics.", error));
        throw error;
    }
};
