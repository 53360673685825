import { createAppAsyncThunk } from "appThunk";
import { cubeLoadParallel } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export class SimilarityMetricValue {
    public readonly id: string;
    public readonly storeId: number;
    public readonly metricNameId: number;
    public readonly metricValue: number;

    constructor(
        storeId: number,
        metricNameId: number,
        metricValue: number
    ) {
        this.id = `${storeId}-${metricNameId}`;
        this.storeId = storeId;
        this.metricNameId = metricNameId;
        this.metricValue = metricValue;
    }
}

export const loadSimilarityMetricValues = createAppAsyncThunk<
    SimilarityMetricValue[],
    { numberOfStores: number, numberOfSimilarityMetrics: number }
>(
    "customer/insights/cost/loadSimilarityMetricValues",
    async ({ numberOfStores, numberOfSimilarityMetrics }, thunkAPI) => {
        try {
            const query = {
                dimensions: [
                    "SimilarityMetric.StoreId",
                    "SimilarityMetric.FK_SimilarityMetricName",
                    "SimilarityMetric.MetricValue"
                ]
            };
            const maxRowCount = numberOfStores * numberOfSimilarityMetrics;
            const rawData = await thunkAPI.dispatch(cubeLoadParallel(query, maxRowCount));

            return rawData.map(row => {
                return new SimilarityMetricValue(
                    Number(row["SimilarityMetric.StoreId"]),
                    Number(row["SimilarityMetric.FK_SimilarityMetricName"]),
                    Number(row["SimilarityMetric.MetricValue"])
                );
            });
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading similarity metric values.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
