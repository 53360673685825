import { RootState } from "store";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, createAppAsyncThunk } from "appThunk";

import { DataWrapper } from "domain/dataWrapper";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { notifyError } from "modules/notifications/notificationsSlice";

import { CostType } from "modules/customer/insights/cost/costType";
import { SimilarityMetric } from "modules/customer/insights/cost/similarityMetric";
import { StoreGroup } from "modules/customer/insights/cost/storeGroups";
import {
    selectCostReductionOpportunityByStore,
    selectCostTypes,
    selectSimilarityMetricValues,
    selectSimilarityMetrics,
    selectStoreGroupCorrelations,
    selectStoreGroups
} from "modules/customer/insights/cost/costSlice";
import { numberSortExpression, stringSortExpression, SortDirection } from "utils/sortUtils";
import { abs } from "mathjs";

export enum CostMeasureType {
    CostAsPercentageOfRevenue,
    CostValue
}

interface CostDriversState {
    isLoading: boolean,
    hasErrors: boolean,
    costCorrelationsCostType?: CostType,
    costCorrelationsMetric?: SimilarityMetric,
    costCorrelationsCostMeasureType: CostMeasureType,
    costCorrelationsStoreGroup?: StoreGroup,
    costDriversCostType?: CostType,
    costDriversStoreGroup?: StoreGroup
}

const initialState: CostDriversState = {
    isLoading: false,
    hasErrors: false,
    costCorrelationsCostType: undefined,
    costCorrelationsMetric: undefined,
    costCorrelationsCostMeasureType: CostMeasureType.CostAsPercentageOfRevenue,
    costCorrelationsStoreGroup: undefined,
    costDriversCostType: undefined,
    costDriversStoreGroup: undefined
};

interface LoadCostDriversResponse {
    costCorrelationsCostType: CostType,
    costCorrelationsMetric: SimilarityMetric,
    costCorrelationsStoreGroup: StoreGroup,
    costDriversCostType: CostType,
    costDriversStoreGroup: StoreGroup
}

const costDriversSlice = createSlice({
    name: "customer/inisghts/cost/costDrivers",
    initialState,
    reducers: {
        clearCostCorrelationsCostType: (state) => {
            state.costCorrelationsCostType = initialState.costCorrelationsCostType;
        },
        clearCostCorrelationsMetric: (state) => {
            state.costCorrelationsMetric = initialState.costCorrelationsMetric;
        },
        clearCostDriversCostType: (state) => {
            state.costDriversCostType = initialState.costDriversCostType;
        },
        clearCostDriversStoreGroup: (state) => {
            state.costDriversStoreGroup = initialState.costDriversStoreGroup;
        },
        setCostCorrelationsCostType: (state, action: PayloadAction<CostType>) => {
            const costType = action.payload;
            state.costCorrelationsCostType = costType;
        },
        setCostCorrelationsMetric: (state, action: PayloadAction<SimilarityMetric>) => {
            const metric = action.payload;
            state.costCorrelationsMetric = metric;
        },
        setCostCorrelationsCostMeasureType: (state, action: PayloadAction<CostMeasureType>) => {
            state.costCorrelationsCostMeasureType = action.payload;
        },
        clearCostCorrelationsCostMeasureType: (state) => {
            state.costCorrelationsCostMeasureType = initialState.costCorrelationsCostMeasureType;
        },
        setCostCorrelationsStoreGroup: (state, action: PayloadAction<StoreGroup>) => {
            state.costCorrelationsStoreGroup = action.payload;
        },
        clearCostCorrelationsStoreGroup: (state) => {
            state.costCorrelationsStoreGroup = initialState.costCorrelationsStoreGroup;
        },
        setCostDriversCostType: (state, action: PayloadAction<CostType>) => {
            const costType = action.payload;
            state.costDriversCostType = costType;
        },
        setCostDriversStoreGroup: (state, action: PayloadAction<StoreGroup>) => {
            const storeGroup = action.payload;
            state.costDriversStoreGroup = storeGroup;
        }
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadCostDrivers.pending, (state: CostDriversState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.costCorrelationsCostType = initialState.costCorrelationsCostType;
            state.costCorrelationsMetric = initialState.costCorrelationsMetric;
            state.costCorrelationsStoreGroup = initialState.costCorrelationsStoreGroup;
            state.costDriversCostType = initialState.costDriversCostType;
            state.costDriversStoreGroup = initialState.costDriversStoreGroup;
        });
        builder.addCase(loadCostDrivers.rejected, (state: CostDriversState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.costCorrelationsCostType = initialState.costCorrelationsCostType;
            state.costCorrelationsMetric = initialState.costCorrelationsMetric;
            state.costCorrelationsStoreGroup = initialState.costCorrelationsStoreGroup;
            state.costDriversCostType = initialState.costDriversCostType;
            state.costDriversStoreGroup = initialState.costDriversStoreGroup;
        });
        builder.addCase(loadCostDrivers.fulfilled, (state: CostDriversState, action: PayloadAction<LoadCostDriversResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.costCorrelationsCostType = action.payload.costCorrelationsCostType;
            state.costCorrelationsMetric = action.payload.costCorrelationsMetric;
            state.costCorrelationsStoreGroup = action.payload.costCorrelationsStoreGroup;
            state.costDriversCostType = action.payload.costDriversCostType;
            state.costDriversStoreGroup = action.payload.costDriversStoreGroup;
        });
    }
});

export const {
    clearCostCorrelationsCostType,
    clearCostCorrelationsMetric,
    clearCostDriversCostType,
    clearCostDriversStoreGroup,
    setCostCorrelationsCostType,
    setCostCorrelationsMetric,
    setCostCorrelationsCostMeasureType,
    setCostCorrelationsStoreGroup,
    setCostDriversCostType,
    setCostDriversStoreGroup
} = costDriversSlice.actions;

export const loadCostDrivers = createAppAsyncThunk(
    "customer/insights/cost/costDrivers/loadCostDrivers",
    async (arg, thunkAPI) => {
        try {
            await thunkAPI.dispatch(setupCube());
            const state = thunkAPI.getState();

            const costTypes = selectCostTypes(state);
            const similarityMetrics = selectSimilarityMetrics(state);
            const storeGroups = selectStoreGroups(state);

            const costCorrelationsCostType = costTypes[0];
            const costCorrelationsMetric = similarityMetrics[0];
            const costCorrelationsStoreGroup = storeGroups[0];
            const costDriversCostType = costTypes[0];
            const costDriversStoreGroup = storeGroups[0];

            const loadCostResponse: LoadCostDriversResponse = {
                costCorrelationsCostType,
                costCorrelationsMetric,
                costCorrelationsStoreGroup,
                costDriversCostType,
                costDriversStoreGroup
            };
            return loadCostResponse;
        }
        catch (error) {
            thunkAPI.dispatch(notifyError("Error loading CostDrivers."));
            return thunkAPI.rejectWithValue(null);
        }
    }
);

export const clearCostDrivers = (): AppThunk => (dispatch) => {
    dispatch(costDriversSlice.actions.clearCostCorrelationsCostType());
    dispatch(costDriversSlice.actions.clearCostCorrelationsCostMeasureType());
    dispatch(costDriversSlice.actions.clearCostCorrelationsMetric());
    dispatch(costDriversSlice.actions.clearCostCorrelationsStoreGroup());
    dispatch(costDriversSlice.actions.clearCostDriversCostType());
    dispatch(costDriversSlice.actions.clearCostDriversStoreGroup());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.hasErrors;
};

export const selectCostCorrelationsCostType = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.costCorrelationsCostType;
};

export const selectCostCorrelationsMetric = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.costCorrelationsMetric;
};

export const selectCostCorrelationsCostMeasureType = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.costCorrelationsCostMeasureType;
};

export const selectCostCorrelationsStoreGroup = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.costCorrelationsStoreGroup;
};

export const selectCostDriversCostType = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.costDriversCostType;
};

export const selectCostDriversStoreGroup = (state: RootState) => {
    return state.customer.insights.cost.costDrivers.costDriversStoreGroup;
};

export const selectFilteredSimilarityMetrics = createSelector(
    selectIsLoading,
    selectHasErrors,
    (state: RootState) => selectSimilarityMetrics(state),
    (state: RootState) => selectSimilarityMetricValues(state),
    (isLoading, hasErrors, similarityMetrics, similarityMetricValues) => {
        if (isLoading || hasErrors || similarityMetricValues.isLoading || similarityMetricValues.hasErrors
            || similarityMetrics.length === 0
        ) {
            return [];
        }

        const similarityMetricsIds = Array.from(new Set(similarityMetricValues.data.map(similarityMetricValue => similarityMetricValue.metricNameId)));

        return similarityMetrics
            .filter(similarityMetric => similarityMetricsIds.includes(similarityMetric.id))
            .sort((a, b) => stringSortExpression(a.name, b.name, SortDirection.ASC));
    }
);

export const selectCostDrivers = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCostDriversCostType,
    selectCostDriversStoreGroup,
    (state: RootState) => selectSimilarityMetrics(state),
    (state: RootState) => selectStoreGroupCorrelations(state),
    (isLoading, hasErrors, costType, storeGroup, similarityMetrics, storeGroupCorrelations) => {
        interface CostDrivers {
            metric: string,
            correlationValue: number
        }

        const costDrivers: DataWrapper<CostDrivers[]> = {
            isLoading: isLoading || storeGroupCorrelations.isLoading,
            hasErrors: hasErrors || storeGroupCorrelations.hasErrors,
            data: []
        };

        if (isLoading || hasErrors ||
            !costType || !storeGroup ||
            !similarityMetrics || !storeGroupCorrelations
        ) {
            return costDrivers;
        }

        costDrivers.data = storeGroupCorrelations.data
            .filter(sgc => sgc.costTypeId === Number(costType.id) && sgc.storeGroup === storeGroup.name)
            .map((sgc) => {
                const metric = similarityMetrics.find(metric => metric.id === sgc.metricNameId)?.name ?? "";
                return {
                    metric: metric,
                    correlationValue: sgc.correlationValue
                };
            })
            .sort((a, b) => numberSortExpression(abs(a.correlationValue), abs(b.correlationValue), SortDirection.DESC));

        return costDrivers;
    }
);

export const selectCostCorrelations = createSelector(
    selectIsLoading,
    selectHasErrors,
    selectCostCorrelationsCostType,
    selectCostCorrelationsMetric,
    selectCostCorrelationsStoreGroup,
    (state: RootState) => selectCostReductionOpportunityByStore(state),
    (state: RootState) => selectSimilarityMetricValues(state),
    (costDriversIsLoading, costDriversHasErrors, costType, metric, storeGroup, costReductionOpportunityByStore, metricValues) => {
        interface Correlations {
            storeId: number,
            storeName: string,
            costValue: number,
            costAsPercentageOfRevenue: number,
            correlationMetricValue: number
        }

        const isLoading = costDriversIsLoading || costReductionOpportunityByStore.isLoading || metricValues.isLoading;
        const hasErrors = costDriversHasErrors || costReductionOpportunityByStore.hasErrors || metricValues.hasErrors;

        const correlations: DataWrapper<Correlations[]> = {
            isLoading: isLoading,
            hasErrors: hasErrors,
            data: []
        };

        if (
            isLoading || hasErrors ||
            costReductionOpportunityByStore.data.length === 0 ||
            !costType || !metric
        ) {
            return correlations;
        }

        const storesFilteredByCostTypeAndGroup = costReductionOpportunityByStore.data
            .filter(item => item.costName === costType.name && item.group === storeGroup?.name);

        correlations.data = storesFilteredByCostTypeAndGroup.map(item => {
            const metricValue = metricValues.data.find(mv => mv.storeId === item.storeId && mv.metricNameId === metric.id)?.metricValue ?? 0;
            return {
                storeId: item.storeId,
                storeName: item.storeName ?? "",
                costValue: item.costValue,
                costAsPercentageOfRevenue: item.costAsPercentageOfRevenue,
                correlationMetricValue: metricValue
            };
        });

        return correlations;
    }
);

export default costDriversSlice;
