import { ResultSet } from "@cubejs-client/core";

import { createAppAsyncThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import arrayUtils from "utils/newArrayUtils";

export class Store {
    public readonly id: number;
    public readonly name: string;
    public readonly openingDate: Date;
    public readonly retailCentreId: number;
    public readonly region: string;
    public readonly clientRegion: string;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly sizeInSquareFeet: number;
    public readonly numberOfEmployees: number;
    public readonly segment: string;
    public readonly format: string;
    public readonly group: string;
    public readonly revenue: number;
    public pitchType: string;

    constructor(
        id: number,
        name: string,
        openingDate: Date,
        retailCentreId: number,
        region: string,
        clientRegion: string,
        latitude: number,
        longitude: number,
        sizeInSquareFeet: number,
        numberOfEmployees: number,
        segment: string,
        format: string,
        group: string,
        revenue: number,
        pitchType: string
    ) {
        this.id = id;
        this.name = name;
        this.openingDate = openingDate;
        this.retailCentreId = retailCentreId;
        this.region = region;
        this.clientRegion = clientRegion;
        this.latitude = latitude;
        this.longitude = longitude;
        this.sizeInSquareFeet = sizeInSquareFeet;
        this.numberOfEmployees = numberOfEmployees;
        this.segment = segment;
        this.format = format;
        this.group = group;
        this.revenue = revenue;
        this.pitchType = pitchType;
    }
}

export const loadStores = createAppAsyncThunk<
    Store[],
    { referenceDate: DateTime }
>(
    "customer/insights/cost/loadStores",
    async ({ referenceDate }, thunkAPI) => {
        try {
            const salesStartDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
            const salesEndDate = referenceDate.endOf('day');
            const storesQuery = {
                measures: [
                    "F_Sales.SumLineValue"
                ],
                timeDimensions: [{
                    dimension: "D_Date.Date",
                    dateRange: [salesStartDate, salesEndDate]
                }],
                dimensions: [
                    "D_Store.PK_Store",
                    "D_Store.StoreName",
                    "D_Store.OpeningDate",
                    "D_Store.RetailCentreID",
                    "D_Store.k_Region",
                    "D_Store.ClientRegion",
                    "D_Store.Lat",
                    "D_Store.Long",
                    "D_Store.Sqft",
                    "D_Store.EmployeeCount",
                    "D_Store.Segment",
                    "D_Store.Format",
                    "D_Store.Group"
                ],
                segments: ["D_Store.OpenPhysicalStores"],
                limit: 50000
            };

            const retailCentresQuery = {
                dimensions: [
                    "D_Store.PK_Store",
                    "Client_RC_RagMetrics.RetailCentreClassification"
                ],
                segments: [
                    "D_Store.OpenPhysicalStores"
                ],
                limit: 50000
            };

            const storesPromise = thunkAPI.dispatch(cubeLoad(storesQuery)) as unknown as ResultSet;
            const retailCentresPromise = thunkAPI.dispatch(cubeLoad(retailCentresQuery)) as unknown as ResultSet;
            const results = await Promise.all([storesPromise, retailCentresPromise]);

            const storesRawData = results[0].rawData();
            const retailCentresRawData = results[1].rawData();
            const combinedRawData = arrayUtils.arrayJoinOnKey([storesRawData, retailCentresRawData], "D_Store.PK_Store", true);

            const stores = combinedRawData.map(row => new Store(
                Number(row["D_Store.PK_Store"]),
                row["D_Store.StoreName"],
                row["D_Store.OpeningDate"],
                row["D_Store.RetailCentreID"],
                row["D_Store.k_Region"],
                row["D_Store.ClientRegion"],
                row["D_Store.Lat"],
                row["D_Store.Long"],
                row["D_Store.Sqft"],
                row["D_Store.EmployeeCount"],
                row["D_Store.Segment"],
                row["D_Store.Format"],
                row["D_Store.Group"],
                row["F_Sales.SumLineValue"],
                row["Client_RC_RagMetrics.RetailCentreClassification"] ?? "Single Pitch"
            ));

            return stores;
        } catch (error) {
            thunkAPI.dispatch(logError("Error loading stores.", error));
            return thunkAPI.rejectWithValue(null);
        }
    }
);
