import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { Store } from "modules/customer/tools/product/store";


export const loadRetailCentreClassification = (selectedStore?: Store): AppThunk<Promise<String>> => async (dispatch) => {
    try {
        if (!selectedStore) {
            return "";
        }
        const query = {
            dimensions: [
                "RetailCentreClassification.RetailCentreClassificationName",
            ],
            filters: [{
                member: "D_RetailCentres.RetailCentreID",
                operator: "equals",
                values: [String(selectedStore.retailCentreID)]
            }]
        };

        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        if (rawData.length === 0) {
            return "Single Pitch";
        }

        return rawData[0]["RetailCentreClassification.RetailCentreClassificationName"];
    } catch (error) {
        dispatch(logError("Error loading RetailCentreClassification.", error));
        throw error;
    }
};
