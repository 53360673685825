import React from "react";

import { Box, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";

import { Partner } from "modules/customer/tools/product/partner";
import numberFormatter from "utils/numberFormatter";
import BarChart from "pages/customer/tools/product/filters/BarChart";

const StyledCard = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main,
        borderColor: (props: any) => props["data-selected"] ? theme.palette.common.white : "transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        height: "100%"
    }
}))(Card);

const StyledCardActions = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.light
    }
}))(CardActions);

const StyledCardActionArea = withStyles(theme => ({
    root: {
        height: "100%",
        minWidth: "100%"
    }
}))(CardActionArea);

const StyledCardContent = withStyles(theme => ({
    root: {
        // @ts-ignore
        backgroundColor: theme.palette.quaternary.main
    }
}))(CardContent);

interface PartnerCardProps {
    partner: Partner,
    selected: boolean,
    onSelect: (partner: Partner) => void,
    onDeselect: (partner: Partner) => void
}

const PartnerCard: React.FC<PartnerCardProps> = (props) => {
    const { partner, selected, onSelect, onDeselect } = props;
    const theme = useTheme();
    const showLabel = true;

    const handleClick = () => {
        if (selected) {
            onDeselect(partner);
        } else {
            onSelect(partner);
        }
    };

    return (
        <StyledCard elevation={0} data-selected={selected}>
            <StyledCardActionArea onClick={handleClick} disableRipple disableTouchRipple>
                <StyledCardActions style={{ height: theme.spacing(12) }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                component="div"
                                align="center"
                            >
                                {partner.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1"
                                component="div"
                                align="center"
                                noWrap
                                style={{ color: theme.palette.text.secondary }}
                            >
                                Headroom
                            </Typography>
                            <Typography
                                variant="h5"
                                component="div"
                                align="center"
                            >
                                {numberFormatter.toGBP(partner.sales.salesHeadroom, 1)}
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledCardActions>
                <StyledCardContent>
                    <BarChart sales={partner.sales} />
                </StyledCardContent>
                {showLabel && <StyledCardActions>
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                        <Typography
                            variant="h6"
                            component="span"
                            align="center"
                            display="inline"
                            style={{ color: theme.palette.text.primary }}
                        >
                            {partner.status}
                        </Typography>
                    </Box>
                </StyledCardActions>}
            </StyledCardActionArea>
        </StyledCard>
    );
};

export default PartnerCard;
