import { apiDelete, apiGet, apiPost, apiPut, ApiResponseStatus } from "modules/helpers/api/apiSlice";
import { notifySuccess, notifyError } from "modules/notifications/notificationsSlice";

import actions from "./actions";
import selectors from "./selectors";

const getUsers = () => async (dispatch) => {
    const response = await dispatch(apiGet("/customer/account/users"));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const users = response.data.users;
            const active = response.data.activeLicenses;
            const remaining = response.data.remainingLicenses;
            dispatch(actions.getUsersSuccess(users));
            dispatch(actions.setLicenses(active, remaining));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("Account not found."));
            dispatch(actions.getUsersFailure());
            dispatch(actions.clearLicenses());
            break;
        }
        default: {
            dispatch(actions.getUsersFailure());
            dispatch(actions.clearLicenses());
            break;
        }
    }
};

const getUser = (id) => async (dispatch, getState) => {
    const state = getState();
    const user = selectors.user(state);
    const response = await dispatch(apiGet(`/customer/account/users/${id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            const newUser = response.data.user;
            dispatch(setUser({ ...user, ...newUser }));
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(hideEditUser());
            dispatch(hideDeleteUser());
            dispatch(notifyError("User not found."));
            break;
        }
        default: {
            break;
        }
    }
};

const addUser = () => async (dispatch, getState) => {
    const state = getState();
    const user = selectors.user(state);
    const recentlyAddedUsers = selectors.recentlyAddedUsers(state);
    const response = await dispatch(apiPost("/customer/account/users", user));
    switch (response.status) {
        case ApiResponseStatus.Ok:
            dispatch(actions.setRecentlyAddedUsers([...recentlyAddedUsers, user]));
            dispatch(clearUser());
            dispatch(notifySuccess("User added."));
            break;
        case ApiResponseStatus.BadRequest: {
            const errors = {
                firstName: response.errorData?.errors?.firstName?.[0],
                lastName: response.errorData?.errors?.lastName?.[0],
                email: response.errorData?.errors?.email?.[0],
                role: response.errorData?.errors?.role?.[0]
            };
            dispatch(actions.setUser({ ...user, errors }));
            break;
        }
        default:
            break;
    }
};

const editUser = () => async (dispatch, getState) => {
    const state = getState();
    const user = selectors.user(state);
    const response = await dispatch(apiPut(`/customer/account/users/${user.id}`, user));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideEditUser());
            dispatch(clearUser());
            dispatch(notifySuccess("User edited."));
            dispatch(getUsers());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(hideEditUser());
            dispatch(notifyError("User not found."));
            break;
        }
        default: {
            break;
        }
    }
};

const deleteUser = () => async (dispatch, getState) => {
    const state = getState();
    const user = selectors.user(state);
    const response = await dispatch(apiDelete(`/customer/account/users/${user.id}`));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideDeleteUser());
            dispatch(clearUser());
            dispatch(notifySuccess("User deleted."));
            dispatch(getUsers());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(notifyError("User not found."));
            break;
        }
        default: {
            break;
        }
    }
};


const changeUserRole = (newRole) => async (dispatch, getState) => {
    const state = getState();
    const user = selectors.user(state);
    const response = await dispatch(apiPut(`/customer/account/users/${user.id}/change-role`, { role: newRole }));
    switch (response.status) {
        case ApiResponseStatus.Ok: {
            dispatch(hideChangeUserRole());
            dispatch(clearUser());
            dispatch(notifySuccess("User role changed."));
            dispatch(getUsers());
            break;
        }
        case ApiResponseStatus.NotFound: {
            dispatch(hideChangeUserRole());
            dispatch(notifyError("User not found."));
            break;
        }
        case ApiResponseStatus.BadRequest: {
            dispatch(notifyError("Invalid role."));
            break;
        }
        default: {
            break;
        }
    }
};

const setUser = (user) => (dispatch) => {
    dispatch(actions.setUser(user));
};

const clearUser = () => (dispatch) => {
    dispatch(actions.clearUser());
};

const showAddUser = () => (dispatch) => {
    dispatch(actions.showAddUser());
};

const hideAddUser = () => (dispatch, getState) => {
    dispatch(actions.hideAddUser());
    const state = getState();
    const recentlyAddedUsers = selectors.recentlyAddedUsers(state);
    if (recentlyAddedUsers.length > 0) {
        dispatch(getUsers());
        dispatch(actions.clearRecentlyAddedUsers());
    }
};

const showEditUser = (id) => (dispatch) => {
    dispatch(actions.showEditUser(id));
};

const hideEditUser = () => (dispatch) => {
    dispatch(actions.hideEditUser());
};

const showDeleteUser = (id) => (dispatch) => {
    dispatch(actions.showDeleteUser(id));
};

const hideDeleteUser = () => (dispatch) => {
    dispatch(actions.hideDeleteUser());
};

const showChangeUserRole = (id) => (dispatch) => {
    dispatch(actions.showChangeUserRole(id));
};

const hideChangeUserRole = () => (dispatch) => {
    dispatch(actions.hideChangeUserRole());
};

const setFilter = (filter) => (dispatch) => {
    dispatch(actions.setFilter(filter));
};

const setSort = (sort) => (dispatch) => {
    dispatch(actions.setSort(sort));
};

const operations = {
    getUsers,
    getUser,
    addUser,
    editUser,
    deleteUser,
    changeUserRole,
    setUser,
    clearUser,
    showAddUser,
    hideAddUser,
    showEditUser,
    hideEditUser,
    showDeleteUser,
    hideDeleteUser,
    showChangeUserRole,
    hideChangeUserRole,
    setFilter,
    setSort
};

export default operations;
