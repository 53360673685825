import { ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

export const loadNumberOfStores = (): AppThunk<Promise<number>> => async (dispatch) => {
    try {
        //ToDo: Change this to general Count
        const query = {
            measures: ["D_Store.Count"],
            segments: ["D_Store.OpenPhysicalStores"]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;
        const rawData = resultSet.rawData();

        if (rawData.length === 0) {
            return 0;
        }
        return rawData[0]["D_Store.CountOpeningDate"];
    } catch (error) {
        dispatch(logError("Error loading SimilarityMetrics.", error));
        throw error;
    }
};
