import { Query, ResultSet } from "@cubejs-client/core";

import { AppThunk } from "appThunk";
import { RetailCentre, StaticRetailCentre } from "modules/customer/tools/location/retailCentre";
import { SpendCategory } from "modules/customer/tools/location/spendCategory";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";

import dateUtils from "utils/dateUtils";

export class Store {
    public readonly id: string;
    public readonly name: string;
    public readonly categoryId: number;
    public readonly categoryName: string;
    public readonly group: string;
    public readonly revenue: number;
    public readonly retailCentre: RetailCentre;
    public readonly latitude: number;
    public readonly longitude: number;
    public readonly clientRegion: string;
    public readonly size: number;
    public readonly format: string;
    public readonly segment: string;
    public readonly openingDate: Date;

    constructor(
        id: string,
        name: string,
        categoryId: number,
        categoryName: string,
        group: string,
        revenue: number,
        retailCentre: RetailCentre,
        latitude: number,
        longitude: number,
        clientRegion: string,
        size: number,
        format: string,
        segment: string,
        openingDate: Date
    ) {
        this.id = id;
        this.name = name;
        this.categoryId = categoryId;
        this.categoryName = categoryName;
        this.group = group;
        this.revenue = revenue;
        this.retailCentre = retailCentre;
        this.latitude = latitude;
        this.longitude = longitude;
        this.clientRegion = clientRegion;
        this.size = size;
        this.format = format;
        this.segment = segment;
        this.openingDate = openingDate;
    }
}

export const loadStores = (
    staticRetailCentres: Map<number, StaticRetailCentre>,
    spendCategories: SpendCategory[],
    enableSpendNew: boolean,
    catchmentAccountId: string
): AppThunk<Promise<Store[]>> => async (dispatch) => {
    if (staticRetailCentres.size === 0) {
        return [];
    }

    try {
        const storesQuery = {
            dimensions: [
                "D_Store.StoreNaturalID",
                "D_Store.StoreName",
                "D_Store.RetailCentreID",
                "D_Store.StoreCategory_ID",
                "D_Store.KPMGStoreCategory",
                "D_Store.Group",
                "D_Store.Lat",
                "D_Store.Long",
                "D_Store.ClientRegion",
                "D_Store.Sqft",
                "D_Store.Format",
                "D_Store.Segment",
                "D_Store.OpeningDate",
                "Store_RAGMetrics.Sales",
            ],
            filters: [{
                member: "D_Store.ClosingDate",
                operator: "notSet"
            }],
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            limit: 50000
        };
        const storesPromise = dispatch(cubeLoad(storesQuery));

        const retailCentresQuery: Query = {
            dimensions: [
                "LocationBenchmarkMetrics.RetailCentreID",
                "LocationBenchmarkMetrics.StoreCategory_ID",
                "LocationBenchmarkMetrics.AffluenceCentile",
                "LocationBenchmarkMetrics.AgeCentile",
                "LocationBenchmarkMetrics.ChildrenCentile",
                "LocationBenchmarkMetrics.DiversityCentile",
                "LocationBenchmarkMetrics.UrbanicityCentile",
                "LocationBenchmarkMetrics.AreaHealthCentile",
                "LocationBenchmarkMetrics.YoYNetOpeningsPercentage",
                "LocationBenchmarkMetrics.FootfallCentile",
                "RetailCentreClassification.RetailCentreClassificationName"
            ],
            filters: [{
                member: "LocationBenchmarkMetrics.Client_ID",
                operator: "equals",
                values: [catchmentAccountId]
            }],
            segments: [
                "LocationBenchmarkMetrics.Baseline"
            ],
            limit: 50000
        };

        if (enableSpendNew) {
            retailCentresQuery.measures = ["LocationBenchmarkSpendMetrics.MeanSpendCentile"];
            retailCentresQuery.filters?.push({
                member: "LocationBenchmarkSpendMetrics.SpendCategory_ID",
                operator: "equals",
                values: spendCategories.map(spendCategory => String(spendCategory.id))
            });
        }

        const retailCentresPromise = dispatch(cubeLoad(retailCentresQuery));
        const results = await Promise.all([storesPromise, retailCentresPromise]);
        const storesResultSet = results[0] as unknown as ResultSet;
        const retailCentresResultSet = results[1] as unknown as ResultSet;

        const rawStores = storesResultSet.rawData();
        const rawRetailCentres = retailCentresResultSet.rawData();
        const retailCentres: RetailCentre[] = [];
        rawRetailCentres.forEach(rawRetailCentre => {
            const retailCentreId = Number(rawRetailCentre["LocationBenchmarkMetrics.RetailCentreID"]);
            const staticRetailCentre = staticRetailCentres.get(retailCentreId);
            if (staticRetailCentre) {
                const retailCentre = new RetailCentre(
                    staticRetailCentre,
                    Number(rawRetailCentre["LocationBenchmarkMetrics.StoreCategory_ID"]),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.AffluenceCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.AgeCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.ChildrenCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.DiversityCentile"] ?? 0),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.UrbanicityCentile"] ?? 0),
                    enableSpendNew ? Number(rawRetailCentre["LocationBenchmarkSpendMetrics.MeanSpendCentile"] ?? 0) : 0,
                    Number(rawRetailCentre["LocationBenchmarkMetrics.AreaHealthCentile"] ?? 0),
                    Boolean(rawRetailCentre["LocationBenchmarkMetrics.YoYNetOpeningsPercentage"] === null),
                    Number(rawRetailCentre["LocationBenchmarkMetrics.FootfallCentile"] ?? 0),
                    String(rawRetailCentre["RetailCentreClassification.RetailCentreClassificationName"] ?? "Single Pitch")
                );
                retailCentres.push(retailCentre);
            }
        });

        const stores: Store[] = [];
        rawStores.forEach(rawStore => {
            const retailCentreId = Number(rawStore["D_Store.RetailCentreID"]);
            const retailCentre = retailCentres.find(rc => (rc.id === retailCentreId) && (rc.storeCategoryId === Number(rawStore["D_Store.StoreCategory_ID"])));
            if (retailCentre) {
                const id = String(rawStore["D_Store.StoreNaturalID"]);
                const name = String(rawStore["D_Store.StoreName"]);
                const categoryId = Number(rawStore["D_Store.StoreCategory_ID"]);
                const categoryName = String(rawStore["D_Store.KPMGStoreCategory"]);
                const group = String(rawStore["D_Store.Group"]);
                const revenue = Number(rawStore["Store_RAGMetrics.Sales"] ?? 0);
                const latitude = Number(rawStore["D_Store.Lat"]);
                const longitude = Number(rawStore["D_Store.Long"]);
                const clientRegion = String(rawStore["D_Store.ClientRegion"]);
                const size = Number(rawStore["D_Store.Sqft"]);
                const format = String(rawStore["D_Store.Format"]);
                const segment = String(rawStore["D_Store.Segment"]);
                const openingDate = dateUtils.dateUTC(rawStore["D_Store.OpeningDate"]);
                const store = new Store(
                    id,
                    name,
                    categoryId,
                    categoryName,
                    group,
                    revenue,
                    retailCentre,
                    latitude,
                    longitude,
                    clientRegion,
                    size,
                    format,
                    segment,
                    openingDate
                );
                stores.push(store);
            }
        });

        return stores;
    } catch (error) {
        dispatch(logError("Error loading Stores.", error));
        throw error;
    }
};
