import { ResultSet } from "@cubejs-client/core";
import { AppThunk } from "appThunk";
import { DateTime } from "luxon";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { ProductSales } from "./productSales";

export enum PartnerStatus {
    ExistingPartner = "Existing partner",
    NewPartner = "New partner"
}

export class Partner {
    public readonly id: number;
    public readonly name: string;
    public readonly sales: ProductSales;
    public readonly status: PartnerStatus;

    constructor(
        id: number,
        name: string,
        clientSourcedSales: number,
        estimatedSales: number,
        optimisedSales: number,
        salesHeadroom: number
    ) {
        this.id = id;
        this.name = name;
        this.sales = new ProductSales(
            String(id),
            name,
            clientSourcedSales,
            estimatedSales,
            optimisedSales,
            salesHeadroom
        );
        this.status = clientSourcedSales > 0 ? PartnerStatus.ExistingPartner : PartnerStatus.NewPartner;
    }
}

export const loadPartners = (referenceDate: DateTime): AppThunk<Promise<Partner[]>> => async (dispatch) => {
    try {
        const startDate = referenceDate.minus({ months: 12 }).plus({ days: 1 }).startOf('day');
        const endDate = referenceDate.endOf('day');
        const query = {
            segments: [
                "D_Store.OpenPhysicalStores"
            ],
            dimensions: [
                "D_Store.Group",
            ],
            timeDimensions: [{
                dimension: "D_Date.Date",
                dateRange: [startDate, endDate]
            }],
            measures: [
                "ProductSalesEstimate.SumClientSourcedSales",
                "ProductSalesEstimate.SumEstimatedSales",
                "ProductSalesEstimate.SumOptimisedSales",
                "ProductSalesEstimate.SumSalesHeadroom"
            ]
        };
        const resultSet = await dispatch(cubeLoad(query)) as unknown as ResultSet;

        let id = 0;
        return resultSet.rawData().map(row => new Partner(
            id++,
            row["D_Store.Group"],
            Number(row["ProductSalesEstimate.SumClientSourcedSales"]),
            Number(row["ProductSalesEstimate.SumEstimatedSales"]),
            Number(row["ProductSalesEstimate.SumOptimisedSales"]),
            Number(row["ProductSalesEstimate.SumSalesHeadroom"])
        ));
    } catch (error) {
        dispatch(logError("Error loading Partners.", error));
        throw error;
    }
};
