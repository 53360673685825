import React from "react";

import { Button } from "@material-ui/core";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import { withStyles } from "@material-ui/core/styles";

import { exportData, selectCanExportData } from "modules/customer/tools/location/dataExport/dataExportSlice";
import { selectPinnedLocation, selectTarget } from "modules/customer/tools/location/locationSlice";
import { selectFeatureFlags } from "modules/featureFlags/featureFlagsSlice";
import { useAppDispatch, useAppSelector } from "store";

const StyledButton = withStyles(theme => ({
    root: {
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.background.default,
        }
    }
}))(Button);

const ExportData: React.FC = () => {
    const dispatch = useAppDispatch();
    const pinnedLocation = useAppSelector(selectPinnedLocation);
    const retailCentre = pinnedLocation?.retailCentre;
    const target = useAppSelector(selectTarget);
    const featureFlags = useAppSelector(selectFeatureFlags);
    const canExportData = useAppSelector(selectCanExportData);

    const handleClick = () => {
        dispatch(exportData());
    };

    if (!featureFlags.enableCustomerToolsLocationExportData || !retailCentre || !target) {
        return null;
    }

    return (
        <StyledButton
            variant="outlined"
            size="small"
            color="default"
            disableElevation
            onClick={handleClick}
            disabled={!canExportData}
            data-cy="btn-export-data"
        >
            <CloudDownloadRoundedIcon />
        </StyledButton>
    );
};

export default ExportData;
