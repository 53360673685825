import { logError } from "modules/helpers/logger/loggerSlice";
import { cubeLoad } from "modules/helpers/cube/cubeSlice";
import dateUtils from "utils/dateUtils";
import { selectReferenceDate } from "modules/customer/insights/performance/performanceSlice";

import actions from "./actions";

const getGrowthBreakdownGrid = () => async (dispatch, getState) => {
    dispatch(actions.getGrowthBreakdownGridRequest());
    try {
        const state = getState();
        const clientReferenceDate = selectReferenceDate(state);
        const prevWeekStart = dateUtils.previousWeekToDateStartDate(clientReferenceDate);
        const clientReferenceWeekStartDate = dateUtils.nextWeekEndDate(prevWeekStart);

        //Need to set this as the end of the latest full week of client sales

        const queryBenchmarkReferenceDate = {
            dimensions: ["F_BenchmarkSalesWithOverall.Date"],
            order: [["F_BenchmarkSalesWithOverall.Date", "desc"]],
            filters: [{
                "member": "F_BenchmarkSalesWithOverall.Sales",
                "operator": "gt",
                "values": ["0"]
            }],
            limit: 1
        };
        const resultSetBenchmarkReferenceDate = await dispatch(cubeLoad(queryBenchmarkReferenceDate));
        const dateString = resultSetBenchmarkReferenceDate.loadResponses[0].data[0]["F_BenchmarkSalesWithOverall.Date"];
        const dateUtc = dateUtils.dateUTC(dateString);
        const benchmarkReferenceDate = dateUtils.endTime(dateUtc);

        const currentDate = clientReferenceWeekStartDate < benchmarkReferenceDate ? clientReferenceWeekStartDate : benchmarkReferenceDate;

        const priorTwelveMonthsStartDate = dateUtils.priorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsStartDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsStartDate(currentDate);
        const twelveMonthsBeforePriorTwelveMonthsEndDate = dateUtils.twelveMonthsBeforePriorTwelveMonthsEndDate(currentDate);

        const latestFullMonthStartDate = dateUtils.latestFullMonthStartDate(currentDate);
        const latestFullMonthEndDate = dateUtils.latestFullMonthEndDate(currentDate);
        const smlyLatestFullMonthStartDate = dateUtils.smlyLatestFullMonthStartDate(currentDate);
        const smlyLatestFullMonthEndDate = dateUtils.smlyLatestFullMonthEndDate(currentDate);

        const latestFullWeekStartDate = dateUtils.weekToDateStartDate(currentDate);
        const latestFullWeekEndDate = dateUtils.nextWeekEndDate(latestFullWeekStartDate);
        const twoWeeksPriorStartDate = dateUtils.previousWeekToDateStartDate(latestFullWeekStartDate);
        const twoWeeksPriorEndDate = dateUtils.nextWeekEndDate(twoWeeksPriorStartDate);
        //New Client Growth Queries
        const queryClientGrowth = {
            measures: [
                "F_Sales.SumLineValue"
            ],
            timeDimensions: [{
                dimension: "D_Date.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate],
                    [latestFullMonthStartDate, latestFullMonthEndDate],
                    [smlyLatestFullMonthStartDate, smlyLatestFullMonthEndDate],
                    [latestFullWeekStartDate, latestFullWeekEndDate],
                    [twoWeeksPriorStartDate, twoWeeksPriorEndDate],
                ]
            }],
            dimensions: [
                "D_ProductCategory.ProductCategory3"
            ],
            order: {
                "F_Sales.SumLineValue": "desc"
            }
        };

        const resultSetClientGrowth = await dispatch(cubeLoad(queryClientGrowth));

        const clientTotalSales = {
            priorTwelveMonths: 0,
            twelveMonthsBeforePriorTwelveMonths: 0,
            latestFullMonth: 0,
            sameMonthLastYear: 0,
            latestFullWeek: 0,
            weekBeforeLatestFullWeek: 0
        };

        const clientCategories = resultSetClientGrowth.loadResponses[0].data.map(item => item["D_ProductCategory.ProductCategory3"]);

        const clientGrowth = [];
        let i = 0;
        for (i in clientCategories) {

            const currentCategory = clientCategories[i];
            clientGrowth[i] = {};
            clientGrowth[i].companyValues = {};

            const priorTwelveMonthsData = resultSetClientGrowth.loadResponses[0].data.find(item => item["D_ProductCategory.ProductCategory3"] === currentCategory);
            const twelveMonthsBeforePriorTwelveMonthsData = resultSetClientGrowth.loadResponses[1].data.find(item => item["D_ProductCategory.ProductCategory3"] === currentCategory);
            if (priorTwelveMonthsData && twelveMonthsBeforePriorTwelveMonthsData) {
                clientGrowth[i].categoryName = currentCategory;
                const priorTwelveMonthSales = priorTwelveMonthsData["F_Sales.SumLineValue"] ?? 0;
                const twelveMonthsBeforePriorTwelveMonthsSales = twelveMonthsBeforePriorTwelveMonthsData["F_Sales.SumLineValue"] ?? 0;
                clientGrowth[i].companyValues.yoyGrowth = (priorTwelveMonthSales === 0) ? 0 :
                    (100 * ((priorTwelveMonthSales - twelveMonthsBeforePriorTwelveMonthsSales) / twelveMonthsBeforePriorTwelveMonthsSales));

                clientTotalSales.priorTwelveMonths += priorTwelveMonthSales;
                clientTotalSales.twelveMonthsBeforePriorTwelveMonths += twelveMonthsBeforePriorTwelveMonthsSales;
            } else {
                clientGrowth[i].companyValues.yoyGrowth = null;
            }

            const latestFullMonthData = resultSetClientGrowth.loadResponses[2].data.find(item => item["D_ProductCategory.ProductCategory3"] === currentCategory);
            const sameMonthLastYearData = resultSetClientGrowth.loadResponses[3].data.find(item => item["D_ProductCategory.ProductCategory3"] === currentCategory);
            if (latestFullMonthData && sameMonthLastYearData) {
                clientGrowth[i].categoryName = currentCategory;
                const latestFullMonthSales = latestFullMonthData["F_Sales.SumLineValue"] ?? 0;
                const sameMonthLastYearSales = sameMonthLastYearData["F_Sales.SumLineValue"] ?? 0;
                clientGrowth[i].companyValues.currentMonthVsSMLYGrowth = (sameMonthLastYearSales === 0) ? 0 :
                    (100 * ((latestFullMonthSales - sameMonthLastYearSales) / sameMonthLastYearSales));

                clientTotalSales.latestFullMonth += latestFullMonthSales;
                clientTotalSales.sameMonthLastYear += sameMonthLastYearSales;
            } else {
                clientGrowth[i].companyValues.currentMonthVsSMLYGrowth = null;
            }

            const latestFullWeekData = resultSetClientGrowth.loadResponses[4].data.find(item => item["D_ProductCategory.ProductCategory3"] === currentCategory);
            const weekBeforeLatestFullWeekData = resultSetClientGrowth.loadResponses[5].data.find(item => item["D_ProductCategory.ProductCategory3"] === currentCategory);
            if (latestFullWeekData && weekBeforeLatestFullWeekData) {
                clientGrowth[i].categoryName = currentCategory;
                const latestFullWeekSales = latestFullWeekData["F_Sales.SumLineValue"] ?? 0;
                const weekBeforeLatestFullWeekSales = weekBeforeLatestFullWeekData["F_Sales.SumLineValue"] ?? 0;
                clientGrowth[i].companyValues.wowGrowth = (weekBeforeLatestFullWeekSales === 0) ? 0 :
                    (100 * ((latestFullWeekSales - weekBeforeLatestFullWeekSales) / weekBeforeLatestFullWeekSales));

                clientTotalSales.latestFullWeek += latestFullWeekSales;
                clientTotalSales.weekBeforeLatestFullWeek += weekBeforeLatestFullWeekSales;
            } else {
                clientGrowth[i].companyValues.wowGrowth = null;
            }

            clientGrowth[i].companyValues.momGrowthTrend = [];
        }

        clientGrowth.push({
            overall: true,
            categoryName: "Company",
            companyValues: {
                yoyGrowth: (clientTotalSales.twelveMonthsBeforePriorTwelveMonths === 0) ? 0 :
                    (100 * ((clientTotalSales.priorTwelveMonths - clientTotalSales.twelveMonthsBeforePriorTwelveMonths) / clientTotalSales.twelveMonthsBeforePriorTwelveMonths)),
                currentMonthVsSMLYGrowth: (clientTotalSales.sameMonthLastYear === 0) ? 0 :
                    (100 * ((clientTotalSales.latestFullMonth - clientTotalSales.sameMonthLastYear) / clientTotalSales.sameMonthLastYear)),
                wowGrowth: (clientTotalSales.weekBeforeLatestFullWeek === 0) ? 0 :
                    (100 * ((clientTotalSales.latestFullWeek - clientTotalSales.weekBeforeLatestFullWeek) / clientTotalSales.weekBeforeLatestFullWeek)),
                momGrowthTrend: [] // Empty Array to be filled with MoM Growth values from seperate Query
            }
        });

        //Client's top 3 Categories
        const filteredClientCategories = resultSetClientGrowth.loadResponses[0].data.slice(0, 3).map(item => item["D_ProductCategory.ProductCategory3"]);

        filteredClientCategories.push("Company");

        const filteredClientGrowth = clientGrowth.filter(item => (
            filteredClientCategories.includes(item.categoryName)
            && item.categoryName)
        );

        const queryClientMOMGrowth = {
            dimensions: [
                "F_MarketCategoryMonthOnMonthGrowth.MarketCategory",
                "F_MarketCategoryMonthOnMonthGrowth.MonthOnMonthGrowth",
                "F_MarketCategoryMonthOnMonthGrowth.MonthDate"
            ],
            timeDimensions: [
                {
                    dimension: "F_MarketCategoryMonthOnMonthGrowth.MonthDate",
                    dateRange: [priorTwelveMonthsStartDate, currentDate]
                }
            ],
            order: [
                [
                    "F_MarketCategoryMonthOnMonthGrowth.MarketCategory",
                    "asc"
                ],
                [
                    "F_MarketCategoryMonthOnMonthGrowth.MonthDate",
                    "asc"
                ]
            ],
            filters: [
                {
                    "member": "F_MarketCategoryMonthOnMonthGrowth.MarketCategory",
                    "operator": "equals",
                    "values": filteredClientCategories
                }
            ]
        };

        const resultSetClientMOMGrowth = await dispatch(cubeLoad(queryClientMOMGrowth));
        const clientMOMGrowth = resultSetClientMOMGrowth.loadResponses[0].data.map(item => ({
            categoryName: item["F_MarketCategoryMonthOnMonthGrowth.MarketCategory"],
            momGrowthTrend: item["F_MarketCategoryMonthOnMonthGrowth.MonthOnMonthGrowth"]
        }));

        for (let i in filteredClientGrowth) {
            const relevantMOMGrowth = clientMOMGrowth.filter(item => item.categoryName === filteredClientGrowth[i].categoryName);
            filteredClientGrowth[i].companyValues.momGrowthTrend = relevantMOMGrowth.map(item => item.momGrowthTrend);
        }

        // Need to calculate momGrowthTrend for Overall/Company category

        const benchmarkCategories = filteredClientCategories.map(item => {
            if (item === "Company") {
                return "Retail";
            } else {
                return item;
            }
        });

        // Benchmark Growth
        const queryBenchmarkGrowth = {
            measures: [
                "F_BenchmarkSalesWithOverall.SumSales"
            ],
            timeDimensions: [{
                dimension: "F_BenchmarkSalesWithOverall.Date",
                compareDateRange: [
                    [priorTwelveMonthsStartDate, currentDate],
                    [twelveMonthsBeforePriorTwelveMonthsStartDate, twelveMonthsBeforePriorTwelveMonthsEndDate],
                    [latestFullWeekStartDate, latestFullWeekEndDate],
                    [twoWeeksPriorStartDate, twoWeeksPriorEndDate],
                ]
            }],
            dimensions: [
                "F_BenchmarkSalesWithOverall.MarketCategory"
            ],
            filters: [{
                "member": "F_BenchmarkSalesWithOverall.MarketCategory",
                "operator": "equals",
                "values": benchmarkCategories
            }],
            order: {
                "F_BenchmarkSalesWithOverall.MarketCategory": "desc"
            }
        };

        const resultSetBenchmarkGrowth = await dispatch(cubeLoad(queryBenchmarkGrowth));

        const benchmarkGrowth = [];
        i = 0;
        for (i in benchmarkCategories) {

            benchmarkGrowth[i] = {};
            benchmarkGrowth[i].benchmarkValues = {};

            if (resultSetBenchmarkGrowth.loadResponses[0].data[i] && resultSetBenchmarkGrowth.loadResponses[1].data[i]) {
                benchmarkGrowth[i].categoryName = resultSetBenchmarkGrowth.loadResponses[0].data[i]["F_BenchmarkSalesWithOverall.MarketCategory"];
                const marketPriorTwelveMonthSales = resultSetBenchmarkGrowth.loadResponses[0].data[i]["F_BenchmarkSalesWithOverall.SumSales"] ?? 0;
                const marketTwelveMonthsBeforePriorTwelveMonthsSales = resultSetBenchmarkGrowth.loadResponses[1].data[i]["F_BenchmarkSalesWithOverall.SumSales"] ?? 0;
                benchmarkGrowth[i].benchmarkValues.yoyGrowth = (marketPriorTwelveMonthSales === 0) ? 0 :
                    (100 * ((marketPriorTwelveMonthSales - marketTwelveMonthsBeforePriorTwelveMonthsSales) / marketTwelveMonthsBeforePriorTwelveMonthsSales));
            } else {
                benchmarkGrowth[i].benchmarkValues.yoyGrowth = null;
            }

            benchmarkGrowth[i].currentMonthVsSMLYGrowth = 0; //Placeholder

            if (resultSetBenchmarkGrowth.loadResponses[2].data[i] && resultSetBenchmarkGrowth.loadResponses[3].data[i]) {
                benchmarkGrowth[i].categoryName = resultSetBenchmarkGrowth.loadResponses[0].data[i]["F_BenchmarkSalesWithOverall.MarketCategory"];
                const marketLatestFullWeekSales = resultSetBenchmarkGrowth.loadResponses[2].data[i]["F_BenchmarkSalesWithOverall.SumSales"] ?? 0;
                const marketWeekBeforeLatestFullWeekSales = resultSetBenchmarkGrowth.loadResponses[3].data[i]["F_BenchmarkSalesWithOverall.SumSales"] ?? 0;
                benchmarkGrowth[i].benchmarkValues.wowGrowth = (marketWeekBeforeLatestFullWeekSales === 0) ? 0 :
                    (100 * ((marketLatestFullWeekSales - marketWeekBeforeLatestFullWeekSales) / marketWeekBeforeLatestFullWeekSales));

            } else {
                benchmarkGrowth[i].benchmarkValues.wowGrowth = null;
            }

            benchmarkGrowth[i].benchmarkValues.momGrowthTrend = [];
        }

        // Month on Month Growth
        const queryBenchMOMGrowth = {
            dimensions: [
                "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MarketCategory",
                "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthOnMonthGrowth",
                "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthRevenue",
                "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthDate",
                "F_BenchmarkMarketCategoryMonthOnMonthGrowth.PrevMonthRevenue"
            ],
            timeDimensions: [
                {
                    dimension: "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthDate",
                    dateRange: [smlyLatestFullMonthEndDate, latestFullMonthEndDate]
                }
            ],
            order: [
                [
                    "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MarketCategory",
                    "asc"
                ],
                [
                    "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthDate",
                    "asc"
                ]
            ],
            filters: [
                {
                    "member": "F_BenchmarkMarketCategoryMonthOnMonthGrowth.MarketCategory",
                    "operator": "equals",
                    "values": benchmarkCategories
                }
            ]
        };

        const resultSetBenchMOMGrowth = await dispatch(cubeLoad(queryBenchMOMGrowth));

        const benchMOMGrowth = resultSetBenchMOMGrowth.loadResponses[0].data.map(item => ({
            categoryName: item["F_BenchmarkMarketCategoryMonthOnMonthGrowth.MarketCategory"],
            monthSales: item["F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthRevenue"],
            previousMonthSales: item["F_BenchmarkMarketCategoryMonthOnMonthGrowth.PrevMonthRevenue"],
            momGrowthTrend: item["F_BenchmarkMarketCategoryMonthOnMonthGrowth.MonthOnMonthGrowth"]
        }));

        for (let i in benchmarkGrowth) {
            const benchMOMfiltered = benchMOMGrowth.filter(item => item.categoryName === benchmarkGrowth[i].categoryName);

            if (benchMOMfiltered.length === 0) {
                continue;
            }

            benchmarkGrowth[i].benchmarkValues.momGrowthTrend = benchMOMfiltered.map(item => item.momGrowthTrend);

            const currentMonthSales = benchMOMfiltered[benchMOMfiltered.length - 1].monthSales;

            const firstEntry = benchMOMfiltered[0];
            const smlySales = firstEntry.previousMonthSales;

            benchmarkGrowth[i].benchmarkValues.currentMonthVsSMLYGrowth = 100 * ((currentMonthSales - smlySales) / smlySales);
        }

        const currentMonth = dateUtils.monthName(latestFullMonthStartDate);

        const sector = {};
        const marketCategories = [];

        for (let i in filteredClientGrowth) {
            if (filteredClientGrowth[i].overall) {
                const relevantBenchGrowth = benchmarkGrowth.find(item =>
                    item.categoryName === "Retail");
                sector.sectorName = relevantBenchGrowth.categoryName;
                sector.companyValues = filteredClientGrowth[i].companyValues;
                sector.benchmarkValues = relevantBenchGrowth.benchmarkValues;
            } else {
                const relevantBenchGrowth = benchmarkGrowth.find(item =>
                    (item.categoryName ?? "").toLowerCase() === filteredClientGrowth[i].categoryName.toLowerCase());
                if (relevantBenchGrowth) {
                    marketCategories.push({
                        categoryName: filteredClientGrowth[i].categoryName,
                        companyValues: filteredClientGrowth[i].companyValues,
                        benchmarkValues: relevantBenchGrowth.benchmarkValues
                    });
                }
            }
        }

        dispatch(actions.getGrowthBreakdownGridSuccess(currentMonth, marketCategories, sector));
    }
    catch (error) {
        dispatch(actions.getGrowthBreakdownGridFailure());
        dispatch(logError("Error loading GrowthBreakdownGrid.", error));
    }
};

const operations = {
    getGrowthBreakdownGrid,
};

export default operations;
