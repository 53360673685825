import { createSelector, createSlice } from "@reduxjs/toolkit";
import { read, writeFileXLSX } from "xlsx";

import { AppThunk } from "appThunk";
import {
    selectCatchmentAreaDemographic,
    selectTotalPopulationInCatchmentArea,
    selectWeightedPopulationInCatchmentArea
} from "modules/customer/tools/location/demographics/demographicsSlice";
import { selectFootfallLevel } from "modules/customer/tools/location/footfall/footfallSlice";
import { selectCatchmentAreaSpendPerHead, selectCatchmentAreaYearlySpend, } from "modules/customer/tools/location/spendNew/spendSlice";
import { apiGet } from "modules/helpers/api/apiSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";

interface DataExportState {
    //
}

const initialState: DataExportState = {
    //
};

const dataExportSlice = createSlice({
    name: "customer/tools/location/dataExport",
    initialState,
    reducers: {
        //
    }
});

// export const {
//     //
// } = dataExportSlice.actions;


export const exportData = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const catchmentAreaDemographic = selectCatchmentAreaDemographic(state);
        const totalPopulationInCatchmentArea = selectTotalPopulationInCatchmentArea(state);
        const weightedPopulationInCatchmentArea = selectWeightedPopulationInCatchmentArea(state);
        const footfallLevel = selectFootfallLevel(state);
        const catchmentAreaSpendPerHead = selectCatchmentAreaSpendPerHead(state);
        const catchmentAreaYearlySpend = selectCatchmentAreaYearlySpend(state);

        const templateUrlResponse = await dispatch(apiGet("/customer/tools/location/data-export-template"));
        const templateUrl = templateUrlResponse.data.url;
        const template = await (await fetch(templateUrl)).arrayBuffer(); //ToDo: using fetch until we can make apiGet deal with xlsx files
        const workbook = read(template);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        worksheet["B2"] = { v: `${catchmentAreaDemographic.retailCentre.affluence}` };
        worksheet["B3"] = { v: `${catchmentAreaDemographic.retailCentre.children}` };
        worksheet["B4"] = { v: `${catchmentAreaDemographic.retailCentre.children}` };
        worksheet["B5"] = { v: `${catchmentAreaDemographic.retailCentre.diversity}` };
        worksheet["B6"] = { v: `${catchmentAreaDemographic.retailCentre.urbanicity}` };

        worksheet["C8"] = { v: `${weightedPopulationInCatchmentArea.retailCentre}` };

        worksheet["D10"] = { v: `${totalPopulationInCatchmentArea.retailCentre}` };

        worksheet["E12"] = { v: `${footfallLevel.selectedLocation}` };

        worksheet["F14"] = { v: `${catchmentAreaSpendPerHead.selectedLocationValue}` };

        worksheet["G16"] = { v: `${catchmentAreaYearlySpend.selectedLocationValue}` };

        writeFileXLSX(workbook, "dash-location-data-export.xlsx");
    } catch (error) {
        dispatch(logError("Error exporting Location data.", error));
        dispatch(notifyError("Error exporting Location data."));
    }
};

export const selectCanExportData = createSelector(
    (state: RootState) => selectTotalPopulationInCatchmentArea(state),
    (state: RootState) => selectWeightedPopulationInCatchmentArea(state),
    (totalPopulationInCatchmentArea, weightedPopulationInCatchmentArea) => {
        return !(totalPopulationInCatchmentArea.isLoading || totalPopulationInCatchmentArea.hasErrors
            || weightedPopulationInCatchmentArea.isLoading || weightedPopulationInCatchmentArea.hasErrors);
    }
);

export default dataExportSlice;
